import { memo } from "react";

import { Swiper, Image } from "react-vant";

import { previewFile } from "@/api/index";

import banner from "@/assets/images/index/banner@2x.png";

import "./index.scss";

const Carousel = ({ data = [] }) => {
  const _data = [banner];

  return (
    <div className="Carousel-swiper">
      <Swiper>
        {_data.map((item, index) => (
          <Swiper.Item key={index}>
            <Image lazyload src={item} fit="cover" />
          </Swiper.Item>
        ))}
      </Swiper>
    </div>
  );
};

export default memo(Carousel);
