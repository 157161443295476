import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
// import { useActivate } from "react-activation";
import { Sticky, Overlay, Toast, NoticeBar } from "react-vant";

import { VolumeO } from "@react-vant/icons";

import dayjs from "dayjs";

import ScrollList from "@/components/ScrollList";
import Carousel from "./component/Carousel";
import SearchFilter from "./component/SearchFilter";
import UserItem from "@/components/UserItem";

import { getUserList } from "@/api";

import useAuthActions from "@/hook/useAuthActions";

import "./index.scss";
import ibg from "@/assets/images/index/ibg@2x.png";
import aw from "@/assets/images/icon/aw@2x.png";

export default function Index() {
  const navigate = useNavigate();
  const { isLogin, isVip } = useAuthActions();
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({
    type: 1,
    start_age: "",
    end_age: "",
    gender: "",
  });

  const loading = useRef(true);
  const toastRef = useRef(null);

  const [listData, setListData] = useState({
    data: [],
    isLoading: false,
    done: false,
    page: 1,
    triggered: false,
  });

  const getNotice = () => {
    const s = dayjs("2024-06-19");
    const n = dayjs();
    const d = n.diff(s, "day");

    return `今天又有${n.hour() + 86}名新会员加入泽缘，我们已成功撮合${
      parseInt(d * 1.5) + 600
    }对`;
  };

  useEffect(() => {
    init();
  }, [filter, isLogin]);

  const init = () => {
    // console.log("init");
    toastRef.current = Toast.loading({
      message: "正在加载…",
      duration: 0,
      forbidClick: true,
    });
    setShowFilter(false);
    fetchData(1);
  };

  const fetchData = async (page, pageSize = 16) => {
    loading.current = true;
    return new Promise(async (resolve) => {
      const { data } = listData;
      try {
        // const fetchWay = params.type === 2 ? getBoxList : getCollectionList;
        const res = await getUserList({
          page,
          pageSize,
          ...filter,
        });

        const list = res?.list || [];
        const dataTotal = res?.count || 0;
        let arr = [];

        if (page === 1) {
          arr = [];
        } else {
          arr = data;
        }

        let done = false;
        arr = arr.concat(list);
        if (arr.length === dataTotal) {
          done = true;
        }
        page++;

        setListData({
          page,
          done,
          isLoading: false,
          data: arr,
        });
      } catch (error) {
        setListData({ ...listData, done: true, isLoading: false });
      } finally {
        toastRef.current?.clear();
        loading.current = false;
        resolve();
      }
    });
  };

  const handleFilter = () => {
    if (!isLogin) {
      navigate("/login");
      return;
    }
    setShowFilter(!showFilter);
  };

  // const handleRefresher = async () => {
  //   init();
  // };

  const handleScrollToLower = async () => {
    if (listData.page === 1 || loading.current) {
      return;
    }

    await fetchData(listData.page);
  };

  const toVip = () => {
    if (!isLogin) {
      navigate("/login");
    } else {
      navigate("/vip");
    }
  };

  const handleReset = () => {
    setFilter({
      type: 1,
      start_age: "",
      end_age: "",
      gender: "",
    });
  };

  const { done, data } = listData;
  return (
    <div className="Index">
      <ScrollList
        // refresherEnabled
        onRefresher={init}
        onScrollToLower={handleScrollToLower}
        done={done}
      >
        <div className="Index-body">
          <Carousel></Carousel>
          <Sticky>
            <div className="Index-filter">
              <div className="Index-filter-type">
                <div
                  className={`Index-filter-type-item ${
                    filter.type === 1 ? "active-type-item" : ""
                  }`}
                  onClick={() => {
                    if (!isLogin) {
                      navigate("/login");
                      return;
                    }
                    setFilter({ ...filter, type: 1 });
                  }}
                >
                  推荐
                </div>
                <div
                  className={`Index-filter-type-item ${
                    filter.type === 2 ? "active-type-item" : ""
                  }`}
                  onClick={() => {
                    if (!isLogin) {
                      navigate("/login");
                      return;
                    }
                    setFilter({ ...filter, type: 2 });
                  }}
                >
                  本地
                </div>
              </div>
              <div className="Index-filter-btn" onClick={handleFilter}>
                <span>筛选</span>
                <img src={aw} alt="" />
              </div>
              <SearchFilter
                onReset={handleReset}
                onSubmit={setFilter}
                show={showFilter}
              ></SearchFilter>
            </div>
          </Sticky>
          <NoticeBar
            leftIcon={<VolumeO />}
            color="#FF55AB"
            background="#FFE1F0"
            text={getNotice()}
          />
          <div className="Index-list">
            {data.map((item) => (
              <UserItem key={item.id} data={item}></UserItem>
            ))}
          </div>
          {!isVip && (
            <img src={ibg} alt="" className="Index-ibg" onClick={toVip} />
          )}
        </div>
        <Overlay
          visible={showFilter}
          zIndex={50}
          onClick={() => setShowFilter(false)}
        />
      </ScrollList>
    </div>
  );
}
