import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import ScrollList from "@/components/ScrollList";
import NavBar from "@/components/NavBar";

import dayjs from "dayjs";

import { getOrderList } from "@/api";
import { copy } from "@/utils";
import useAuthActions from "@/hook/useAuthActions";
import "./index.scss";
import copy_icon from "@/assets/images/icon/copy@2x.png";

const Order = () => {
  const { isLogin } = useAuthActions();

  const [listData, setListData] = useState({
    data: [],
    isLoading: false,
    done: false,
    page: 1,
    triggered: false,
  });

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    if (!isLogin) {
      return;
    }
    fetchData(1);
  };

  const fetchData = async (page, pageSize = 16) => {
    return new Promise(async (resolve) => {
      const { data } = listData;
      try {
        // const fetchWay = params.type === 2 ? getBoxList : getMyContractList;
        const res = await getOrderList({
          page,
        });

        const list = res?.list || [];
        const dataTotal = res?.count || 0;
        let arr = [];
        if (page === 1) {
          arr = [];
        } else {
          arr = data;
        }
        let done = false;
        arr = arr.concat(list);
        if (arr.length === dataTotal) {
          done = true;
        }
        page++;

        setListData({
          page,
          done,
          data: arr,
        });
      } catch (error) {
        setListData({ ...listData, done: true });
      } finally {
        resolve();
      }
    });
  };

  const handleRefresher = async () => {
    init();
  };

  const handleScrollToLower = async () => {
    if (listData.page === 1) {
      return;
    }
    await fetchData(listData.page);
  };

  if (!isLogin) {
    return <Navigate to="/" replace={true}></Navigate>;
  }

  const { done, data } = listData;
  return (
    <div className="Order">
      <div className="Order-bar">
        <NavBar title="我的订单"></NavBar>
      </div>
      <ScrollList
        onRefresher={init}
        onScrollToLower={handleScrollToLower}
        done={done}
        finishedText={data.length === 0 ? "" : "已全部加载"}
      >
        <div className="Order-list">
          {data.map((item) => (
            <div className="Order-item" key={item.id}>
              <div className="Order-item-row">
                <div className="Order-item-name">
                  {item.order_type === 1 ? item?.card_name || "" : "红娘服务"}
                </div>
                <div className="Order-item-num" onClick={() => copy(item.id)}>
                  订单号:{item.order_no}
                  <img src={copy_icon} alt="" />
                </div>
              </div>
              <div className="Order-item-row">
                <div className="Order-item-date">
                  {dayjs(item.created_at).format("YYYY-MM-DD HH:mm:ss")}
                </div>
                <div className="Order-item-price">-¥{item.amount / 100}</div>
              </div>
            </div>
          ))}
        </div>
      </ScrollList>
    </div>
  );
};

export default Order;
