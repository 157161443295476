import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";

import { Link } from "react-router-dom";

import md5 from "js-md5";

import NavBar from "@/components/NavBar";
import { Toast, Dialog } from "react-vant";

import { setUserInfo, setToken } from "@/store/reducer";

import { login, getUserInfo } from "@/api";

import { validatePhone, kfLink } from "@/utils/index";

import logo from "@/assets/images/login/logo@2x.png";
import slogan from "@/assets/images/login/slogan@2x.png";
import phone_icon from "@/assets/images/login/phone@2x.png";
import lock_icon from "@/assets/images/login/lock@2x.png";
import eye_open from "@/assets/images/login/eye_open@2x.png";
import eye_close from "@/assets/images/login/eye_close@2x.png";
import "./index.scss";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const code = useSelector((state) => {
    return state?.code;
  });
  // const [type, setType] = useState("password");

  const [checked, setChecked] = useState(true);

  const [passwordType, setPasswordType] = useState("password");
  const [form, setForm] = useState({
    user_name: "",
    password: "",
  });

  const handleLogin = async () => {
    const { user_name, password } = form;
    if (!user_name) {
      Toast.info("请输入账号");

      return;
    }
    if (!validatePhone(user_name)) {
      Toast.info("请输入正确的手机号");
      return;
    }

    if (!password) {
      Toast.info("请输入密码");

      return;
    }
    if (password.length < 6) {
      Toast.info("密码不能少于6位");

      return;
    }

    const toast = Toast.loading({
      message: "正在登录…",
      duration: 0,
      forbidClick: true,
    });

    try {
      const sendObj = {
        user_name,
        password: md5(password),
        code: code.open_id,
      };

      const data = await login(sendObj);

      dispatch(setToken(data.token));
      const user = await getUserInfo();

      dispatch(setUserInfo(user || {}));
      toast.clear();

      // navigate("/", { replace: true });
    } catch (error) {
      const text = error?.msg || "登录失败，请稍后重试";
      // console.log("error", error);
      toast.clear();
      Toast.info(text);
    }
  };

  const isLogin = useSelector((state) => {
    return !!state?.userInfo?.id;
  });

  const handleForget = () => {
    Dialog.confirm({
      title: "忘记密码？",
      message: "请联系客服更改密码",
      onCancel: () => {},
      onConfirm: () => {
        window.open(kfLink);
      },
      confirmButtonText: "联系客服",
      confirmButtonColor: "#FF55AB",
      // cancelButtonColor: "#999999",
    });
  };

  return isLogin ? (
    <Navigate to="/" replace={true}></Navigate>
  ) : (
    <div className="Login">
      <NavBar title="登录" black></NavBar>
      <img src={logo} alt="logo" className="Login-logo" />
      <img src={slogan} alt="slogan" className="Login-slogan" />
      <div className="Login-form">
        <div className="Login-form-row">
          <img src={phone_icon} alt="" className="Login-form-img" />
          <input
            type="text"
            value={form.user_name}
            onInput={(e) => {
              setForm({ ...form, user_name: e.target.value });
            }}
            placeholder="请输入手机号"
          />
        </div>
        <div className="Login-form-row">
          <img src={lock_icon} alt="" className="Login-form-img" />
          <input
            type={passwordType}
            value={form.password}
            onInput={(e) => {
              setForm({ ...form, password: e.target.value });
            }}
            placeholder="请输入密码"
          />
          <img
            src={passwordType === "password" ? eye_close : eye_open}
            alt=""
            className="Login-eye-icon"
            onClick={() => {
              setPasswordType(
                passwordType === "password" ? "text" : "password"
              );
            }}
          />
        </div>

        <div className="Login-form-tip">
          <span onClick={handleForget}>忘记密码？</span>
          <Link to={"/registered"}>注册账号！</Link>
        </div>
        <button className="Login-form-btn" onClick={handleLogin}>
          登录
        </button>
        <div
          className="Login-form-agreement"
          onClick={() => {
            setChecked(!checked);
          }}
        >
          <div
            className={`Login-check ${checked ? "Login-check-active" : ""}`}
          ></div>
          已阅读并同意<Link to={"/privacy"}>《隐私声明》</Link>
        </div>
      </div>
    </div>
  );
}
