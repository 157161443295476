import axios from "axios";

import store from "../store";
import { clearUser } from "@/store/reducer";

const request = axios.create();

request.interceptors.request.use((config) => {
  if (
    !config.url?.includes("/login") ||
    !config.url?.includes("/file/upload") ||
    !config.url?.includes("/register") ||
    !config.url?.includes("/send_sms")
  ) {
    const token = store.getState().token || "";

    config.headers.token = token;
  }

  return config;
});

request.interceptors.response.use((res) => {
  if (res?.status === 200 && res.data?.code === 200) {
    return res.data?.data;
  } else if (
    res.data?.code === 401 &&
    res.config.url.includes("/api/user/info")
  ) {
    store.dispatch(clearUser());
    // window.location.href = "/login";
    return Promise.reject(res?.data);
  } else if (res.data?.code === 401) {
    store.dispatch(clearUser());
    window.location.href = "/login";
    return Promise.reject(res?.data);
  }

  return Promise.reject(res?.data);
});

export default request;
