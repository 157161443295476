import "./index.scss";

import NavBar from "@/components/NavBar";

const ServerPage = () => {
  const list = [
    {
      title: "一、协议概述",
      desc: "本协议为泽缘婚介红娘服务购买协议，旨在明确泽缘婚介（以下简称“本公司”）与购买红娘服务的用户（以下简称“会员”）之间的权利与义务。通过购买红娘服务，会员将享受本公司提供的一对一专业红娘服务，由红娘协助会员与合适的相亲对象进行线下见面。",
    },
    {
      title: "二、服务内容",
      list: [
        {
          label: "红娘服务",
          text: "本公司将为会员安排专业红娘，根据会员的个人信息和需求，为其筛选并推荐合适的相亲对象。红娘将协助会员与相亲对象进行沟通，并约定线下见面时间、地点等事宜。",
        },
        {
          label: "个性化咨询",
          text: "红娘将为会员提供个性化的情感咨询和婚恋建议，帮助会员更好地处理情感问题。",
        },
        {
          label: "服务跟进",
          text: "在会员与相亲对象见面后，红娘将进行服务跟进，了解会员的反馈和需求，为会员提供后续服务。",
        },
      ],
    },
    {
      title: "三、购买与支付",
      list: [
        {
          text: "会员需按照本公司公布的价格购买红娘服务，并支付相应费用。",
        },
        {
          text: "支付成功后，会员将享受红娘服务的相应权益。",
        },
      ],
    },
    {
      title: "四、会员权益",
      list: [
        {
          text: "会员购买红娘服务后将享受本公司提供的上述红娘服务。",
        },
        {
          text: "会员在享受服务过程中，如有任何疑问或建议，可随时与本公司联系，本公司将竭诚为您解答和提供帮助。",
        },
      ],
    },
    {
      title: "五、服务期限",
      list: [
        {
          text: "红娘服务的期限自会员支付成功之日起计算，具体期限以本公司公布的信息为准。",
        },
        {
          text: "服务期限内，本公司将为会员提供约定的红娘服务次数。如需增加服务次数，会员需另行购买并支付费用。",
        },
      ],
    },
    {
      title: "六、违约责任",
      list: [
        {
          text: "如会员在购买或使用过程中违反本协议规定，本公司有权立即终止其红娘服务，并不予退还已支付的费用。",
        },
      ],
    },
    {
      title: "六、免责条款",
      list: [
        {
          text: "本公司提供的服务仅供参考，会员在使用服务过程中应自行判断信息的真实性和可靠性，并承担由此产生的风险。",
        },
        {
          text: "会员与相亲对象之间的见面、交往等行为，均由双方自行负责，本公司不承担由此产生的任何责任。",
        },
      ],
    },
    {
      title: "七、联系方式",
      list: [
        {
          text: "如会员有任何疑问或需要帮助，请随时联系本公司客服，我们将竭诚为您服务。",
        },
      ],
    },
  ];
  return (
    <div className="VipPage">
      <div className="VipPage-head">
        <NavBar title="红娘服务购买协议"></NavBar>
      </div>
      <div className="VipPage-body">
        <ul className="VipPage-list">
          {list.map((item) => (
            <li key={item.title} className="VipPage-list-item">
              <div className="VipPage-list-title">{item.title}</div>
              {item.desc && <p className="VipPage-list-desc">{item.desc}</p>}
              {item.list?.map((sitem, index) => (
                <li className="VipPage-list-content">
                  <span>{index + 1}.</span>
                  {sitem.label && (
                    <span className="VipPage-list-label">{sitem.label}：</span>
                  )}
                  {sitem.text}
                </li>
              ))}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ServerPage;
