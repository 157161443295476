import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { useActivate } from "react-activation";
import { getCardList } from "@/api";

import dayjs from "dayjs";

import { Toast } from "react-vant";

// import useAuthActions from "@/hook/useAuthActions";

import "./index.scss";
import vip_card from "@/assets/images/vip/vip_card@2x.png";
import i1 from "@/assets/images/vip/i1@2x.png";
import i2 from "@/assets/images/vip/i2@2x.png";
import i3 from "@/assets/images/vip/i3@2x.png";

const Vip = () => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => ({
    userInfo: state.userInfo,
  }));

  const isLogin = !!userInfo?.id;
  const isVip = userInfo?.is_vip === 1;
  // const { isLogin } = useAuthActions();
  const [data, setData] = useState([]);

  const init = async () => {
    const toast = Toast.loading({
      message: "",
      duration: 0,
      forbidClick: false,
    });
    try {
      const res = await getCardList();

      setData(res || []);
      toast.clear();
    } catch (error) {
      const text = error?.msg || "网络异常，请稍后重试";
      // console.log("error", error);
      toast.clear();
      Toast.info(text);
    }
  };

  const handleClick = (id) => {
    if (!isLogin) {
      navigate("/login");
      return;
    }
    navigate(`/pay?id=${id}`);
  };

  const renderExp = () => {
    if (!userInfo.card_exp) {
      return "";
    }
    return dayjs(userInfo.card_exp).format("YYYY-MM-DD");
  };

  const renderList = () => {
    const { membership_benefits = "" } = userInfo;
    if (!membership_benefits) {
      return null;
    }
    const arr = membership_benefits.split("$$");
    return arr.splice(0, 3).map((item) => (
      <li className="Mine-vip-info-item" key={item}>
        {item}
      </li>
    ));
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="Vip">
      <div className="Vip-body">
        <div className="Vip-content">
          {isVip ? (
            <div className="Mine-vip">
              <div className="Mine-vip-name">{userInfo.card_name}</div>
              <div className="Mine-vip-date">到期时间：{renderExp()}</div>
              <ul className="Mine-vip-info">{renderList()}</ul>
            </div>
          ) : (
            data.map((item) => (
              <div
                className="vip-card"
                onClick={() => {
                  handleClick(item.id);
                }}
                key={item.id}
              >
                <img src={vip_card} alt="" />
                <div className="vip-card-name">{item.card_name}</div>
                <div className="vip-card-price">
                  <span className="vip-card-price-cur">
                    ¥{item.sale_price / 100}
                  </span>

                  {item.source_price ? (
                    <span className="vip-card-price-og">
                      ¥{item.source_price / 100}
                    </span>
                  ) : null}
                </div>
                <div className="vip-card-tip">开通会员·尊享多项特权</div>
                <div className="vip-card-btn">立即开通</div>
              </div>
            ))
          )}

          <div className="vip-equity">
            <div className="vip-equity-title">会员尊享特权</div>
            <ul className="vip-equity-list">
              <li className="vip-equity-item">
                <img src={i1} alt="" className="vip-equity-item-img" />
                <div className="vip-equity-info">
                  <div className="vip-equity-info-title">
                    一对一专业红娘服务
                  </div>
                  <div className="vip-equity-info-desc">
                    优先推荐线下相亲对象
                  </div>
                </div>
              </li>
              <li className="vip-equity-item">
                <img src={i2} alt="" className="vip-equity-item-img" />
                <div className="vip-equity-info">
                  <div className="vip-equity-info-title">千万优质对象资料</div>
                  <div className="vip-equity-info-desc">
                    自由匹配会员资料及预览
                  </div>
                </div>
              </li>
              <li className="vip-equity-item">
                <img src={i3} alt="" className="vip-equity-item-img" />
                <div className="vip-equity-info">
                  <div className="vip-equity-info-title">线下聚会真实可靠</div>
                  <div className="vip-equity-info-desc">相亲Party入会资格</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vip;
