import { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";

import { Image, Toast, ImagePreview, Checkbox, Dialog } from "react-vant";

import {
  previewFile,
  getUserData,
  putCollect,
  getRedPrice,
  payReadOrder,
} from "@/api";
import useAuthActions from "@/hook/useAuthActions";

import { copy, kfLink } from "@/utils";

import "./index.scss";
// import dw_icon from "@/assets/images/icon/dw@2x.png";
import copy_icon from "@/assets/images/icon/copy@2x.png";
import fh_icon from "@/assets/images/icon/fh_icom@2x.png";
import nv_icon from "@/assets/images/icon/nu@2x.png";
import nan_icon from "@/assets/images/icon/nan@2x.png";
import heart from "@/assets/images/icon/heart@2x.png";
import heart_1 from "@/assets/images/icon/heart_1@2x.png";
import w_logo from "@/assets/images/w_logo@2x.png";

const User = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const { isLogin, isVip, isAuth } = useAuthActions();
  const [checked, setChecked] = useState(true);
  const [data, setData] = useState({});
  const [priceData, setPriceData] = useState({});
  const isCollect = data?.is_collect === 1;

  const renderImgs = () => {
    const arr = data?.life_photos?.split(",") || [];

    return arr.map((item, index) => (
      <div
        className="User-imgs-item"
        key={item}
        onClick={(e) => {
          e.stopPropagation();
          previewImg(index + 1);
        }}
      >
        <Image src={previewFile(item)} fit="cover" alt="" />
      </div>
    ));
  };

  const renderLabel = () => {
    const {
      gender,
      age,
      height = "",
      weight = "",
      zodiac_sign = "",
      constellations = "",
      blood_type = "",
      marriage = "",
      occupation = "",
      house_available,
      // car_available,
    } = data;
    const arr = [
      { label: "性别", value: gender === 1 ? "男" : "女" },
      { label: "年龄", value: age ? age + "岁" : "" },
      { label: "身高", value: height ? height + "CM" : "" },
      { label: "体重", value: weight ? weight / 100 + "KG" : "" },
      { label: "生肖", value: zodiac_sign },
      { label: "星座", value: constellations },
      { label: "血型", value: blood_type },
      { label: "情感", value: marriage },
      { label: "职业", value: occupation },
      {
        label: "房屋",
        value: house_available ? (house_available === 1 ? "有房" : "无房") : "",
      },
      // {
      //   label: "车辆",
      //   value: car_available == 1 ? "有车" : "无车",
      // },
    ];

    return arr.map((item) => (
      <div className="User-base-item" key={item.label}>
        <div className="User-base-item-label">{item.label}</div>
        <div className="User-base-item-value">
          {item.value}
          <div className="User-item-value"></div>
        </div>
      </div>
    ));
  };

  const init = () => {
    fetchData();
  };

  const fetchData = async () => {
    if (!isLogin) {
      navigate("/login");
      return;
    }
    if (!isVip) {
      Dialog.confirm({
        title: "未开通会员",
        message: "开通会员后方可查看会员资料",
        onCancel: () => {
          navigate(-1);
        },
        onConfirm: () => {
          navigate("/vip");
          // window.open("https://work.weixin.qq.com/kfid/kfcbe66ba8134220a50");
        },
        confirmButtonText: "前往开通",
        confirmButtonColor: "#FF55AB",
        cancelButtonText: "返回",
        // cancelButtonColor: "#999999",
      });
      return;
    }

    if (!id) {
      return;
    }
    const toast = Toast.loading({
      message: "正在加载…",
      duration: 0,
      forbidClick: false,
    });

    try {
      const res = await getUserData(id);
      const obj = await getRedPrice();

      setData(res);
      setPriceData(obj);

      toast.clear();
    } catch (error) {
      const text = error?.msg || "网络异常，请稍后重试";
      // console.log("error", error);
      toast.clear();
      Toast.info(text);
    }
  };

  const handleCollect = async () => {
    try {
      await putCollect(data.id, isCollect ? "delete" : "add");
      setData({ ...data, is_collect: isCollect ? 2 : 1 });
      Toast.info({
        message: isCollect ? "已取消收藏" : "添加收藏成功",
        duration: 1000,
      });
    } catch (error) {
      const text = error?.msg || "网络异常，请稍后重试";
      // console.log("error", error);
      Toast.info(text);
    }
  };

  const handleContact = () => {
    window.open(kfLink);
  };

  const handlePay = async () => {
    if (!isAuth) {
      Dialog.confirm({
        title: "未实名认证",
        message: "实名认证后方可购买红娘服务",
        onCancel: () => {},
        onConfirm: () => {
          navigate("/auth");
        },
        confirmButtonText: "前往认证",
        confirmButtonColor: "#FF55AB",
        cancelButtonText: "返回",
        // cancelButtonColor: "#999999",
      });

      return;
    }
    if (!checked) {
      Toast.info("未勾选协议");
      return;
    }
    const { amount } = priceData;
    if (!amount) {
      return;
    }
    const toast = Toast.loading({
      message: "",
      duration: 0,
      forbidClick: false,
    });
    let res = {};
    try {
      res = await payReadOrder({ amount });
      toast.clear();
      // console.log(res);
    } catch (error) {
      const text = error?.msg || "网络异常，请稍后重试";
      // console.log("error", error);
      toast.clear();
      Toast.info(text);
    }

    // eslint-disable-next-line
    WeixinJSBridge.invoke("getBrandWCPayRequest", res, function (res) {
      if (res.err_msg == "get_brand_wcpay_request:ok") {
        // 使用以上方式判断前端返回,微信团队郑重提示：
        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
        getRedPrice().then((res) => {
          setPriceData(res || {});
        });
      }
    });
  };

  const previewImg = (index = 0) => {
    if (!data.head_url) {
      return;
    }
    const _arr = data?.life_photos?.split(",") || [];
    let arr = [data.head_url];
    arr = arr.concat(_arr);
    console.log(arr);
    const images = arr.map((item) => previewFile(item));
    ImagePreview.open({ images, startPosition: index, closeable: true });
  };

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    init();
  }, []);

  return (
    <div className="User">
      <div className="User-cover">
        <Image
          src={previewFile(data.head_url)}
          lazyload
          fit="cover"
          alt=""
          onClick={() => {
            previewImg();
          }}
        />
        <img
          src={fh_icon}
          alt=""
          className="User-back"
          onClick={() => {
            navigate(-1);
          }}
        />
        <div className="User-cover-bottom">
          {/* <div className="User-location">
            <img src={dw_icon} alt="" /> 广州天河区
          </div> */}
          <div className="User-imgs">
            <div className="User-imgs-list">{renderImgs()}</div>
          </div>
        </div>
      </div>
      {data.id ? (
        <div className="User-body">
          <div className="User-head">
            <div className="User-head-left">
              <div className="User-head-top">
                <span className="User-head-name">{data?.user_name}</span>
                <img
                  src={data.gender === 1 ? nan_icon : nv_icon}
                  alt=""
                  className="User-head-sex"
                />
                {data.user_no ? (
                  <>
                    <span
                      className="User-head-code"
                      onClick={() => {
                        copy(data.user_no);
                      }}
                    >
                      {data.user_no}
                    </span>
                    <img
                      src={copy_icon}
                      alt=""
                      className="User-head-copy"
                      onClick={() => {
                        copy(data.user_no);
                      }}
                    />
                  </>
                ) : null}
              </div>
              <div className="User-head-desc">
                <span className="User-head-desc-item">{data.age}岁</span>
                <span className="User-head-desc-item">
                  {data.gender === 1 ? "男" : "女"}
                </span>
                <span className="User-head-desc-item">
                  {data.current_place_of_residence_province || ""}
                  {data.current_place_of_residence_city || ""}
                  {data.current_place_of_residence_area || ""}
                </span>
              </div>
            </div>
            <div className="User-head-collect">
              <div className="User-collect" onClick={handleCollect}>
                <img src={isCollect ? heart_1 : heart}></img>
              </div>
              <span>收藏</span>
            </div>
            <img src={w_logo} alt="" className="User-logo" />
          </div>

          {data?.personal_signature && (
            <section className="User-section">
              <div className="User-section-title">个性签名:</div>
              <p className="User-intro">{data?.personal_signature}</p>
            </section>
          )}

          <section className="User-section">
            <div className="User-section-title">基本资料:</div>
            <div className="User-base">
              {renderLabel()}

              {data.hometown_area ? (
                <div className="User-base-item User-base-item-long">
                  <div className="User-base-item-label">籍贯</div>
                  <div className="User-base-item-value">
                    <div className="User-item-value">
                      {data.hometown_province +
                        data.hometown_city +
                        data.hometown_area}
                    </div>
                  </div>
                </div>
              ) : null}

              {data.current_place_of_residence_area ? (
                <div className="User-base-item User-base-item-long">
                  <div className="User-base-item-label">现居地</div>
                  <div className="User-base-item-value">
                    <div className="User-item-value">
                      {data.current_place_of_residence_province +
                        data.current_place_of_residence_city +
                        data.current_place_of_residence_area}
                    </div>
                  </div>
                </div>
              ) : null}

              {data.household_registration_location_area ? (
                <div className="User-base-item User-base-item-long">
                  <div className="User-base-item-label">户口所在地</div>
                  <div className="User-base-item-value">
                    <div className="User-item-value">
                      {data.household_registration_location_province +
                        data.household_registration_location_city +
                        data.household_registration_location_area}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="User-section-logo"></div>
          </section>

          {data?.simple_family_situation && (
            <section className="User-section">
              <div className="User-section-title">简单家庭情况:</div>
              <p className="User-desc">{data?.simple_family_situation}</p>
            </section>
          )}

          {data?.requirement && (
            <section className="User-section">
              <div className="User-section-title">征友要求描述:</div>
              <p className="User-desc">{data?.requirement}</p>
            </section>
          )}
        </div>
      ) : null}

      {data.id && isVip ? (
        priceData.have_write_off === 1 ? (
          <div className="User-bottom">
            <button className="User-bottom-btn" onClick={handleContact}>
              联系客服
            </button>
          </div>
        ) : (
          <div className="Pay-bottom">
            <div className="Pay-bottom-left">
              <div className="Pay-price">
                <span className="Pay-price-label">{data.card_name}</span>¥
                {priceData.amount / 100}
              </div>
              <div className="Pay-am">
                <Checkbox
                  checked={checked}
                  onChange={setChecked}
                  checkedColor="#FF55AB"
                  iconSize="14px"
                >
                  <span className="Pay-am-text">我已阅读并同意</span>
                  <Link to={"/serverPage"}>《红娘服务协议》</Link>
                </Checkbox>
              </div>
            </div>
            <button className="Pay-bottom-btn" onClick={handlePay}>
              购买红娘服务
            </button>
          </div>
        )
      ) : null}
    </div>
  );
};

export default User;
