import { memo, useEffect, useState, useRef } from "react";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

import useBack from "@/hook/back";

import fh from "@/assets/images/icon/fh@2x.png";
import b_fh from "@/assets/images/icon/b_fh@2x.png";

import "./index.scss";

const NavBar = ({
  title = "",
  bg = false,

  style = {},
  rightBtn,
  black = false,
}) => {
  const back = useBack();

  const navRef = useRef();


  return (
    <nav className={`NavBar  ${black ? "" : "wbg"}`} style={style} ref={navRef}>
      <img
        src={black ? fh : b_fh}
        alt=""
        className="NavBar-back"
        onClick={back}
      />
      {title}

      {rightBtn ? <div className="NavBar-right">{rightBtn}</div> : null}
    </nav>
  );
};

export default memo(NavBar);
