import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  Sticky,
  Input,
  Form,
  Radio,
  DatetimePicker,
  Picker,
  Uploader,
  Toast,
} from "react-vant";

import dayjs from "dayjs";
import NavBar from "@/components/NavBar";

import cityOptions from "./city";

// import Input from "@/components/form/input";
import { setUserInfo } from "@/store/reducer";

import { upload, previewFile, putUserInfo } from "@/api";

import "./index.scss";
import more_icon from "@/assets/images/icon/more_icom_24@2x.png";
import w_logo from "@/assets/images/w_logo@2x.png";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const is_registered = searchParams.get("is_registered");
  const { userInfo } = useSelector((state) => ({
    userInfo: state.userInfo,
  }));
  const formatUser = () => {
    const obj = { ...userInfo };
    if (obj.life_photos) {
      const arr = obj.life_photos.split(",");

      obj._life_photos = arr.map((item, index) => ({
        url: previewFile(item),
        flieKey: item,
      }));
    }

    if (obj.birth_year && obj.birth_month && obj.birth_day) {
      obj._date = new Date(
        `${obj.birth_year}-${obj.birth_month}-${obj.birth_day}`
      );
    }
    if (obj.weight) {
      obj._weight = obj.weight / 100;
    }
    if (
      obj.current_place_of_residence_province &&
      obj.current_place_of_residence_city &&
      obj.current_place_of_residence_area
    ) {
      obj._current_place = [
        obj.current_place_of_residence_province,
        obj.current_place_of_residence_city,
        obj.current_place_of_residence_area,
      ];
    }

    if (
      obj.household_registration_location_province &&
      obj.household_registration_location_city &&
      obj.household_registration_location_area
    ) {
      obj._household = [
        obj.household_registration_location_province,
        obj.household_registration_location_city,
        obj.household_registration_location_area,
      ];
    }
    if (obj.hometown_province && obj.hometown_city && obj.hometown_area) {
      obj._hometown = [
        obj.hometown_province,
        obj.hometown_city,
        obj.hometown_area,
      ];
    }
    return obj;
  };

  const [formData, setFormData] = useState(formatUser(userInfo));
  const [form] = Form.useForm();
  const date = Form.useWatch("_date", form);

  const zodiacs = [
    "鼠",
    "牛",
    "虎",
    "兔",
    "龙",
    "蛇",
    "马",
    "羊",
    "猴",
    "鸡",
    "狗",
    "猪",
  ];

  const signs = [
    { name: "水瓶座", startDay: 20, month: 1 },
    { name: "双鱼座", startDay: 19, month: 2 },
    { name: "白羊座", startDay: 21, month: 3 },
    { name: "金牛座", startDay: 20, month: 4 },
    { name: "双子座", startDay: 21, month: 5 },
    { name: "巨蟹座", startDay: 22, month: 6 },
    { name: "狮子座", startDay: 23, month: 7 },
    { name: "处女座", startDay: 23, month: 8 },
    { name: "天秤座", startDay: 23, month: 9 },
    { name: "天蝎座", startDay: 23, month: 10 },
    { name: "射手座", startDay: 22, month: 11 },
    { name: "摩羯座", startDay: 22, month: 12 },
  ];
  const bloodTypes = ["A型", "B型", "O型", "AB型", "RH型"];

  const signsOptions = signs.map((item) => item.name);

  const onFinish = async (fields) => {
    const sendObj = { ...formData, ...fields };

    if (!formData.head_url) {
      Toast.info("请上传头像");
      return;
    }
    if (fields._date) {
      //  const date =
      const _date = dayjs(fields._date).format("YYYY-MM-DD");
      const arr = _date.split("-");
      sendObj.birth_year = arr[0];
      sendObj.birth_month = arr[1];
      sendObj.birth_day = arr[2];
    }

    if (fields._weight) {
      sendObj.weight = roundToDecimalPlace(fields._weight * 100, 0);
    } else {
      sendObj.weight = 0;
    }

    if (fields.height) {
      sendObj.height = roundToDecimalPlace(fields.height, 0);
    } else {
      sendObj.height = 0;
    }

    if (fields._current_place && fields._current_place.length === 3) {
      sendObj.current_place_of_residence_province = fields._current_place[0];
      sendObj.current_place_of_residence_city = fields._current_place[1];
      sendObj.current_place_of_residence_area = fields._current_place[2];
    }
    if (fields._household && fields._household.length === 3) {
      sendObj.household_registration_location_province = fields._household[0];
      sendObj.household_registration_location_city = fields._household[1];
      sendObj.household_registration_location_area = fields._household[2];
    }
    if (fields._hometown && fields._hometown.length === 3) {
      sendObj.hometown_province = fields._hometown[0];
      sendObj.hometown_city = fields._hometown[1];
      sendObj.hometown_area = fields._hometown[2];
    }
    if (fields._life_photos && fields._life_photos?.length > 0) {
      const arr = [];
      // fields._life_photos.
      fields._life_photos.forEach((item) => {
        if (item.flieKey) {
          arr.push(item.flieKey);
        }
      });
      sendObj.life_photos = arr.join(",");
    }
    sendObj.status = 1;

    Object.keys(sendObj).forEach((key) => {
      if (key[0] === "_") {
        delete sendObj[key];
      }
    });
    const toast = Toast.loading({
      message: "正在提交",
      duration: 0,
      forbidClick: true,
    });
    try {
      await putUserInfo(sendObj);
      dispatch(setUserInfo(sendObj));
      toast.clear();
      Toast.success({
        message: "提交成功",
        duration: 1000,
        forbidClick: true,
        onClose: () => {
          if (is_registered) {
            navigate("/", { replace: true });
          } else {
            navigate(-1);
          }
        },
      });
    } catch (error) {
      const text = error?.msg || "提交失败，请稍后重试";
      // console.log("error", error);
      toast.clear();
      Toast.info(text);
    }
  };
  const roundToDecimalPlace = (num, decimalPlaces) => {
    const factor = Math.pow(10, decimalPlaces);
    const temp = num * factor;
    const rounded = Math.round(temp);
    return rounded / factor;
  };

  const submit = () => {
    form.submit();
  };

  const calculateAge = (birthDate) => {
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  function getZodiacSign(birthday) {
    const month = birthday.getMonth() + 1; // getMonth() 返回的月份是从0开始的，所以需要+1
    const day = birthday.getDate();

    if ((month === 1 && day >= 20) || (month === 2 && day <= 18))
      return "水瓶座";
    if ((month === 2 && day >= 19) || (month === 3 && day <= 20))
      return "双鱼座";
    if ((month === 3 && day >= 21) || (month === 4 && day <= 19))
      return "白羊座";
    if ((month === 4 && day >= 20) || (month === 5 && day <= 20))
      return "金牛座";
    if ((month === 5 && day >= 21) || (month === 6 && day <= 21))
      return "双子座";
    if ((month === 6 && day >= 22) || (month === 7 && day <= 22))
      return "巨蟹座";
    if ((month === 7 && day >= 23) || (month === 8 && day <= 22))
      return "狮子座";
    if ((month === 8 && day >= 23) || (month === 9 && day <= 22))
      return "处女座";
    if ((month === 9 && day >= 23) || (month === 10 && day <= 22))
      return "天秤座";
    if ((month === 10 && day >= 23) || (month === 11 && day <= 21))
      return "天蝎座";
    if ((month === 11 && day >= 22) || (month === 12 && day <= 21))
      return "射手座";
    if ((month === 12 && day >= 22) || (month === 1 && day <= 19))
      return "摩羯座";

    return "未知星座"; // 非法日期可能到达这里
  }

  function getZodiacYear(birthYear) {
    const currentZodiacIndex = (birthYear - 1900) % 12;
    return zodiacs[currentZodiacIndex];
  }

  const columnsFieldNames = {
    text: "label",
    value: "value",
    children: "children",
  };

  const checkEmpty = (arr) => {
    if (!arr) {
      return false;
    }
    return !arr.every((item) => {
      return !!item;
    });
  };

  const toIndex = () => {
    navigate("/", { replace: true });
  };

  const handleAvatarChange = async (event) => {
    if (event.target.files.length > 0) {
      const flie = event.target.files[0];
      try {
        const res = await upload(flie);
        const obj = res[0];

        setFormData({ ...formData, head_url: obj.address });
      } catch (error) {}
    }
  };

  const handleUpload = async (flie) => {
    try {
      try {
        const res = await upload(flie);
        const obj = res[0];
        return { url: previewFile(obj.address), flieKey: obj.address };
      } catch (error) {}
    } catch (error) {
      return { url: "" };
    }
  };

  useEffect(() => {
    if (date) {
      const _date = new Date(date);
      const age = calculateAge(date);

      const zodiac_sign = getZodiacYear(_date.getFullYear());
      const constellations = getZodiacSign(_date);
      // console.log(age, zodiac_sign, constellations);
      form?.setFieldsValue({
        age,
        zodiac_sign,
        constellations,
      });
    }
  }, [date]);

  return (
    <div className="Profile">
      <Sticky>
        <div className="Profile-nav">
          <NavBar title="填写资料"></NavBar>
        </div>
      </Sticky>
      <div className="Profile-body">
        <Form form={form} onFinish={onFinish} initialValues={formData}>
          {is_registered ? (
            <div className="Profile-tip">
              <div className="Profile-tip-top">
                <div className="Profile-tip-title">注册成功！</div>
                <div className="Profile-tip-more" onClick={toIndex}>
                  跳过
                  <img src={more_icon} alt="跳过" />
                </div>
              </div>
              <p className="Profile-tip-desc">
                请完善个人信息，以便未您精准推荐优质伴侣
              </p>
            </div>
          ) : null}

          <div className="Profile-avatar">
            <div className="Profile-avatar-input">
              {formData.head_url ? (
                <img src={previewFile(formData.head_url)} alt="" />
              ) : null}
              <div className="Profile-avatar-input-label">上传头像</div>
              <input
                type="file"
                onChange={handleAvatarChange}
                accept="image/*"
              />
            </div>
            <div className="Profile-avatar-tip">
              上传真实头像，提高推荐成功率！
            </div>
            <img src={w_logo} alt="" className="Profile-avatar-l1" />
            <img src={w_logo} alt="" className="Profile-avatar-l2" />
          </div>

          <section className="Profile-section">
            <div className="Profile-section-title">
              基本信息<span></span>
            </div>
            <div className="Profile-section-desc">
              完善基本资料，让对方更了解您
            </div>
            <div className="Profile-section-body">
              <Form.Item
                rules={[{ required: true, message: "请填写姓名" }]}
                name="user_name"
                label="姓名"
              >
                <Input placeholder="请输入姓名" />
              </Form.Item>
              <Form.Item
                name="gender"
                label="性别"
                rules={[{ required: true, message: "请选择性别" }]}
              >
                <Radio.Group direction="horizontal" checkedColor="#FF55AB">
                  <Radio name={1}>男</Radio>
                  <Radio name={2}>女</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                isLink
                name="_date"
                label="生日"
                trigger="onConfirm"
                onClick={(_, action) => {
                  action.current?.open();
                }}
                rules={[{ required: true, message: "请选择出生日期" }]}
              >
                <DatetimePicker
                  popup
                  type="date"
                  minDate={new Date(1949, 0, 1)}
                  maxDate={new Date()}
                >
                  {(val) =>
                    val ? (
                      <span className="row-value">
                        {dayjs(val).format("YYYY-MM-DD")}
                      </span>
                    ) : (
                      "请选择出生日期"
                    )
                  }
                </DatetimePicker>
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "请填写年龄" }]}
                name="age"
                label="年龄"
              >
                <Input placeholder="请输入年龄" type="digit" />
              </Form.Item>

              <Form.Item
                isLink
                name="marriage"
                label="情感"
                trigger="onConfirm"
                onClick={(_, action) => {
                  action.current?.open();
                }}
                rules={[{ required: true, message: "请选择情感状况" }]}
              >
                <Picker popup columns={["未婚", "离异", "丧偶"]}>
                  {(val) =>
                    val ? (
                      <span className="row-value">{val}</span>
                    ) : (
                      "请选择情感状况"
                    )
                  }
                </Picker>
              </Form.Item>
              <Form.Item
                isLink
                name="_current_place"
                label="现居地"
                trigger="onConfirm"
                onClick={(_, action) => {
                  action.current?.open();
                }}
                rules={[
                  { required: true, message: "请选择地区" },
                  {
                    validator: (_, value) => {
                      if (!checkEmpty(value)) {
                        return Promise.resolve(true);
                      }
                      return Promise.reject(new Error("请选择地区"));
                    },
                  },
                ]}
              >
                <Picker
                  popup
                  columns={cityOptions}
                  columnsFieldNames={columnsFieldNames}
                >
                  {(val, _) => {
                    return checkEmpty(val) ? (
                      "请选择居住地"
                    ) : (
                      <span className="row-value">{val?.join("-")}</span>
                    );
                  }}
                </Picker>
              </Form.Item>
            </div>
          </section>

          <section className="Profile-section">
            <div className="Profile-section-title">
              个人生活照<span>（可上传多张）</span>
            </div>
            <div className="Profile-section-desc">
              完善个人生活照，获得更多异性青睐
            </div>
            <div className="Profile-section-body">
              <Form.Item
                name="_life_photos"
                // rules={[{ required: true, message: "请选择照片" }]}
                maxCount={6}
              >
                <Uploader multiple upload={handleUpload} />
              </Form.Item>
            </div>
          </section>

          <section className="Profile-section">
            <div className="Profile-section-title">
              详细信息<span></span>
            </div>
            <div className="Profile-section-desc">
              完善详细资料，提高匹配成功率
            </div>
            <div className="Profile-section-body">
              <Form.Item
                // rules={[{ required: true, message: "请填写身高" }]}
                name="height"
                label="身高"
              >
                <Input placeholder="请输入身高" suffix={"CM"} type="digit" />
              </Form.Item>
              <Form.Item
                // rules={[{ required: true, message: "请填写身高" }]}
                name="_weight"
                label="体重"
              >
                <Input placeholder="请输入体重" suffix={"KG"} type="number" />
              </Form.Item>
              <Form.Item
                isLink
                name="zodiac_sign"
                label="生肖"
                trigger="onConfirm"
                onClick={(_, action) => {
                  action.current?.open();
                }}
                // rules={[{ required: true, message: "请选择生肖" }]}
              >
                <Picker popup columns={zodiacs}>
                  {(val) =>
                    val ? (
                      <span className="row-value">{val}</span>
                    ) : (
                      "请选择生肖"
                    )
                  }
                </Picker>
              </Form.Item>
              <Form.Item
                isLink
                name="constellations"
                label="星座"
                // rules={[{ required: true, message: "请选择星座" }]}
                trigger="onConfirm"
                onClick={(_, action) => {
                  action.current?.open();
                }}
              >
                <Picker popup columns={signsOptions}>
                  {(val) =>
                    val ? (
                      <span className="row-value">{val}</span>
                    ) : (
                      "请选择星座"
                    )
                  }
                </Picker>
              </Form.Item>

              <Form.Item
                isLink
                name="blood_type"
                label="血型"
                trigger="onConfirm"
                onClick={(_, action) => {
                  action.current?.open();
                }}
              >
                <Picker popup columns={bloodTypes}>
                  {(val) =>
                    val ? (
                      <span className="row-value">{val}</span>
                    ) : (
                      "请选择血型"
                    )
                  }
                </Picker>
              </Form.Item>
              <Form.Item
                isLink
                name="marriage"
                label="婚姻"
                trigger="onConfirm"
                onClick={(_, action) => {
                  action.current?.open();
                }}
              >
                <Picker popup columns={["单身", "离异"]}>
                  {(val) =>
                    val ? (
                      <span className="row-value">{val}</span>
                    ) : (
                      "请选择婚姻状况"
                    )
                  }
                </Picker>
              </Form.Item>
              <Form.Item
                isLink
                name="house_available"
                label="房屋"
                trigger="onConfirm"
                onClick={(_, action) => {
                  action.current?.open();
                }}
              >
                <Picker
                  popup
                  columns={[
                    { text: "有房", value: 1 },
                    { text: "无房", value: 2 },
                  ]}
                >
                  {(val, _) =>
                    val ? (
                      <span className="row-value">{_.text}</span>
                    ) : (
                      "请选择房屋状况"
                    )
                  }
                </Picker>
              </Form.Item>
              {/* <Form.Item
                isLink
                name="car_available"
                label="车辆"
                trigger="onConfirm"
                onClick={(_, action) => {
                  action.current?.open();
                }}
              >
                <Picker
                  popup
                  columns={[
                    { text: "有车", value: 1 },
                    { text: "无车", value: 2 },
                  ]}
                >
                  {(val, _) =>
                    val ? (
                      <span className="row-value">{_.text}</span>
                    ) : (
                      "请选择车辆状况"
                    )
                  }
                </Picker>
              </Form.Item> */}
              <Form.Item
                isLink
                name="_hometown"
                label="籍贯"
                trigger="onConfirm"
                onClick={(_, action) => {
                  action.current?.open();
                }}
                rules={[
                  {
                    validator: (_, value) => {
                      if (!checkEmpty(value)) {
                        return Promise.resolve(true);
                      }
                      return Promise.reject(new Error("请选择地区"));
                    },
                  },
                ]}
              >
                <Picker
                  popup
                  columns={cityOptions}
                  columnsFieldNames={columnsFieldNames}
                >
                  {(val, _) => {
                    return checkEmpty(val) ? (
                      "请选择籍贯"
                    ) : (
                      <span className="row-value">{val?.join("-")}</span>
                    );
                  }}
                </Picker>
              </Form.Item>

              <Form.Item
                isLink
                name="_household"
                label="户口所在地"
                trigger="onConfirm"
                onClick={(_, action) => {
                  action.current?.open();
                }}
                rules={[
                  {
                    validator: (_, value) => {
                      if (!checkEmpty(value)) {
                        return Promise.resolve(true);
                      }
                      return Promise.reject(new Error("请选择地区"));
                    },
                  },
                ]}
              >
                <Picker
                  popup
                  columns={cityOptions}
                  columnsFieldNames={columnsFieldNames}
                >
                  {(val, _) => {
                    return checkEmpty(val) ? (
                      "请选择户口所在地"
                    ) : (
                      <span className="row-value">{val?.join("-")}</span>
                    );
                  }}
                </Picker>
              </Form.Item>
            </div>
          </section>

          <section className="Profile-section">
            <div className="Profile-section-title">
              家庭情况<span></span>
            </div>
            <div className="Profile-section-desc">
              完善家庭情况，让别人了解您的家庭范围
            </div>
            <div className="Profile-section-body">
              <Form.Item name="simple_family_situation">
                <Input.TextArea
                  rows={3}
                  autoSize
                  placeholder="请输入家庭情况"
                />
              </Form.Item>
            </div>
          </section>

          <section className="Profile-section">
            <div className="Profile-section-title">
              征友要求<span></span>
            </div>
            <div className="Profile-section-desc">
              完善征友要求，找到更合适您的人
            </div>
            <div className="Profile-section-body">
              <Form.Item name="requirement">
                <Input.TextArea
                  rows={3}
                  autoSize
                  placeholder="请输入征友要求"
                />
              </Form.Item>
            </div>
          </section>
        </Form>
      </div>

      <div className="Profile-bottom">
        <button className="Profile-bottom-btn" onClick={submit}>
          提交
        </button>
      </div>
    </div>
  );
};

export default Profile;
