import { configureStore } from "@reduxjs/toolkit";

import reducer from "./reducer"; // 导入 切片的 reducer

const store = configureStore({
  reducer,
  // reducer: {
  //   user: userReducer, // 绑定切片的 reducer
  // },
});

export default store;
