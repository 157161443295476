import { useState } from "react";
import md5 from "js-md5";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, Navigate } from "react-router-dom";
import { Toast } from "react-vant";
import NavBar from "@/components/NavBar";
import { clearUser } from "@/store/reducer";
// import { setUserInfo } from "@/store/reducer";

import { rePwd } from "@/api/index";

import "./index.scss";

import eye_open from "@/assets/images/icon/eye_open@2x.png";
import eye_close from "@/assets/images/icon/eye_close@2x.png";
import sj from "@/assets/images/icon/sj@2x.png";
import mima from "@/assets/images/icon/mima@2x.png";

const RowInput = ({ label = "", value, onChange = () => {}, img = "" }) => {
  const [type, setType] = useState("password");
  return (
    <div className="row-input">
      <div className="row-input-wrap">
        {img && <img src={img} alt="" className="row-input-bef" />}
        <input
          type={type}
          placeholder={label}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
        <img
          className="row-input-after"
          src={type === "password" ? eye_close : eye_open}
          alt=""
          onClick={() => {
            if (type === "password") {
              setType("text");
            } else {
              setType("password");
            }
          }}
        />
      </div>
    </div>
  );
};

export default function EditPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userInfo);
  // const use_pwd = userInfo.operation_password;
  // const [readOnly, setReadOnly] = useState(false);

  const [password, setPassword] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const handleClick = async () => {
    if (!password.old_password) {
      Toast.info("请输入原密码");
      return;
    }
    if (!password.new_password) {
      Toast.info("请输入新密码");
      return;
    }
    if (password.new_password !== password.confirm_password) {
      Toast.info("请再次确认新密码");
      return;
    }
    // if (use_pwd) {
    //   if (use_pwd !== md5(password.origin)) {
    //     Toast.show({ content: "旧操作密码不正确" });
    //     return;
    //   }
    // } else if (password.new !== password.origin) {
    //   Toast.show({ content: "两次输入的密码不正确" });
    //   return;
    // }

    const toast = Toast.loading({
      message: "",
      duration: 0,
      forbidClick: true,
    });

    try {
      await rePwd({
        uid: userInfo.id,
        old_password: md5(password.old_password),
        new_password: md5(password.new_password),
      });
      toast.clear();
      Toast.success({
        message: "修改成功！请重新登录",
        forbidClick: true,
        duration: 1000,
        onClose: () => {
          dispatch(clearUser());
          navigate("/login", { replace: true });
        },
      });
    } catch (error) {
      toast.clear();
      Toast.info(error.msg || "网络异常，请稍后再试");
    }
  };

  // const RightBtn = () => {
  //   return (
  //     <button
  //       className={`EditPassword-btn ${
  //         !password.old_password ||
  //         !password.old_password ||
  //         !password.confirm_password
  //           ? "EditPassword-btn-disabled"
  //           : ""
  //       }`}
  //       onClick={handleClick}
  //     >
  //       完成
  //     </button>
  //   );
  // };

  return (
    <div className="EditPassword">
      <NavBar title={"修改密码"} bg></NavBar>

      <div className="EditPassword-form">
        <RowInput
          img={sj}
          label={"请输入旧密码"}
          value={password.origin}
          onChange={(value) => {
            setPassword({ ...password, old_password: value });
          }}
        ></RowInput>
        <RowInput
          img={mima}
          label={"请输入新密码"}
          value={password.new}
          onChange={(value) => {
            setPassword({ ...password, new_password: value });
          }}
        ></RowInput>
        <RowInput
          img={mima}
          label={"再次确认新密码"}
          value={password.new}
          onChange={(value) => {
            setPassword({ ...password, confirm_password: value });
          }}
        ></RowInput>
      </div>

      <button className="EditPassword-btn" onClick={handleClick}>
        确认
      </button>
    </div>
  );
}
