import { createSlice } from "@reduxjs/toolkit";
// import { setItem } from "@/utils/storage";

const initialState = {
  userInfo: {
    id: "",
    phone: "",
    nickname: "",
    user_name: "",
  },

  currentForm: {
    id: "",
    keyArr: [],
  },
  htmlData: {},
  code: {
    open_id: "",
    proxy_id: 0,
  },
};

/**
 * 传入一个配置对象
 * name:命名空间，基本没啥用
 * initialState:初始化的 state
 * reducers:{
 *           [actionName]:(state,action:{type,payload})=>{ //直接修改state即可，底层是使用的immer不用关心}
 *           , ... }
 * */
const userReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      const newUserInfo = {
        ...state.userInfo,
        ...(action.payload || {}),
        token: "",
      };

      state.userInfo = newUserInfo;
      // setItem("userInfo", JSON.stringify(newUserInfo));
    },
    setCurrentForm: (state, action) => {
      state.currentForm = action.payload || {};
    },
    setHtmlData: (state, action) => {
      state.htmlData = action.payload || {};
    },

    setToken: (state, action) => {
      // console.log("action", action);
      const token = action.payload || "";
      state.token = token;
      localStorage.setItem("token", token);
      // console.log('state.token', action.payload);
    },

    setHomeActive: (state, action) => {
      state.homeActive = action.payload;
    },
    setCode: (state, action) => {
      state.code = action.payload;
    },

    clearUser: (state) => {
      state.userInfo = {};
      state.token = "";
      state.code.proxy_id = 0;
      state.currentForm = {
        id: "",
        keyArr: [],
      };
      // localStorage.removeItem("token");
      localStorage.clear();
    },
  },
});

// 会从切片中 reducers 创建 actions 供外部使用
export const {
  setUserInfo,
  setCurrentForm,
  setHtmlData,

  setToken,
  clearUser,

  setHomeActive,
  setCode,
} = userReducer.actions;

// 默认导出 切片的 reducer
export default userReducer.reducer;
