import NavBar from "@/components/NavBar";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Toast } from "react-vant";
import { setUserInfo } from "@/store/reducer";
import { authUser, getUserInfo } from "@/api";
import { validateIdCard } from "@/utils";
import "./index.scss";
const Authentication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => ({
    userInfo: state.userInfo,
  }));

  const [form, setForm] = useState({
    real_name: "",
    id_card: "",
  });

  const submit = async () => {
    const { real_name, id_card } = form;
    if (!real_name) {
      Toast.info("请输入姓名");
      return;
    }
    if (!id_card || !validateIdCard(id_card)) {
      Toast.info("身份证号码格式不正确");
      return;
    }

    const toast = Toast.loading({
      message: "",
      duration: 0,
      forbidClick: true,
    });
    try {
      await authUser({
        real_name,
        id_card,
        id: userInfo.id,
      });
      const user = await getUserInfo();

      dispatch(setUserInfo(user || {}));
      toast.clear();
      Toast.success({
        message: "认证成功！",
        duration: 1000,
        forbidClick: true,
        onClose: () => {
          navigate(-1);
        },
      });
    } catch (error) {
      const text = error?.msg || "认证失败，请稍后重试";
      // console.log("error", error);
      toast.clear();
      Toast.info(text);
    }
  };

  return (
    <div className="Authentication">
      <NavBar title="实名认证"></NavBar>

      <div className="Authentication-form">
        <div className="row-input-wrap">
          <span className="row-input-label">真实姓名</span>
          <input
            type="text"
            value={form.real_name}
            placeholder="请输入姓名"
            onChange={(e) => setForm({ ...form, real_name: e.target.value })}
          />
        </div>
        <div className="row-input-wrap">
          <span className="row-input-label">证件号码</span>
          <input
            type="text"
            value={form.id_card}
            placeholder="请输入身份证号码"
            onChange={(e) => setForm({ ...form, id_card: e.target.value })}
          />
        </div>

        <button className="Authentication-btn" onClick={submit}>
          提交
        </button>
      </div>
    </div>
  );
};

export default Authentication;
