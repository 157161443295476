import NavBar from "@/components/NavBar";
import "./index.scss";

import p1 from "@/assets/images/agency/p1@2x.png";
import p2 from "@/assets/images/agency/p2@2x.png";
import p3 from "@/assets/images/agency/p3@2x.png";
import p4 from "@/assets/images/agency/p4@2x.png";
// import p5 from "@/assets/images/agency/p5@2x.png";
import p6 from "@/assets/images/agency/p6@2x.png";
import p7 from "@/assets/images/agency/p7@2x.png";
const Agency = () => {
  return (
    <div className="Agency">
      <div className="Agency-head">
        <NavBar title="成为代理" black></NavBar>
      </div>
      <img src={p1} alt="" className="Agency-img" />
      <img src={p2} alt="" className="Agency-img" />
      <img src={p3} alt="" className="Agency-img" />
      <img src={p4} alt="" className="Agency-img" />
      {/* <img src={p5} alt="" className="Agency-img" /> */}
      <img src={p6} alt="" className="Agency-img" />
      <img src={p7} alt="" className="Agency-img" />
    </div>
  );
};
export default Agency;
