import { Suspense } from "react";
import KeepAlive, { AliveScope } from "react-activation";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Home from "./Home";

import Login from "@/pages/Login";
import Registered from "@/pages/Registered";
import Privacy from "@/pages/Privacy";

import Index from "@/pages/Index";
import Mine from "@/pages/Mine";
import Vip from "@/pages/Vip";
import User from "@/pages/User";
import Profile from "@/pages/Profile";
import Pay from "@/pages/Pay";
import Heartbeat from "@/pages/Heartbeat";
import Order from "@/pages/Order";
import About from "@/pages/About";
import VipPage from "@/pages/VipPage";
import ServerPage from "@/pages/ServerPage";
import Authentication from "@/pages/Authentication";
import Agency from "@/pages/Agency";

// import AboutUs from "@/pages/AboutUs";

// import Setting from "@/pages/Setting";

import EditPassword from "@/pages/EditPassword";

const Loading = () => <div></div>;

//saveScrollPosition={"screen"}

const router = createBrowserRouter([
  // {
  //   path: "/forget",
  //   element: (
  //     <Suspense fallback={<Loading></Loading>}>
  //       <Forget></Forget>
  //     </Suspense>
  //   ),
  // },
  // {
  //   path: "/agreement",

  //   element: (
  //     <Suspense fallback={<Loading></Loading>}>
  //       <Agreement></Agreement>
  //     </Suspense>
  //   ),
  // },
  {
    path: "/privacy",
    element: (
      <Suspense fallback={<Loading></Loading>}>
        <Privacy></Privacy>
      </Suspense>
    ),
  },
  {
    path: "/",
    element: <Home></Home>,
    children: [
      {
        index: true,
        element: (
          <KeepAlive
            id={"index"}
            name="index"
            cacheKey="index"
            saveScrollPosition="screen"
          >
            <Index></Index>
          </KeepAlive>
        ),
      },
      // {
      //   path: "/index",
      //   element: (
      //     <KeepAlive id={"/index"} saveScrollPosition={"screen"}>
      //       <Index></Index>
      //     </KeepAlive>
      //   ),
      // },

      // {
      //   path: "/index",
      //   element: (
      //     <KeepAlive id={"/index"} saveScrollPosition={"screen"}>
      //       <Index></Index>
      //     </KeepAlive>
      //   ),
      // },
      {
        path: "/vip",
        element: <Vip></Vip>,
      },

      {
        path: "/mine",
        element: (
          <KeepAlive id={"/mine"} cacheKey="mine">
            <Mine></Mine>
          </KeepAlive>
        ),
      },
    ],
  },
  {
    path: "/user/:id",
    element: <User></User>,
  },
  {
    path: "/profile",
    element: <Profile></Profile>,
  },
  {
    path: "/login",
    element: <Login></Login>,
  },
  {
    path: "/registered",
    element: <Registered></Registered>,
  },
  {
    path: "/pay",
    element: <Pay></Pay>,
  },

  {
    path: "/heartbeat",
    element: <Heartbeat></Heartbeat>,
  },
  {
    path: "/order",
    element: <Order></Order>,
  },
  {
    path: "/editPassword",
    element: <EditPassword></EditPassword>,
  },
  {
    path: "/about",
    element: <About></About>,
  },
  {
    path: "/vipPage",
    element: <VipPage></VipPage>,
  },
  {
    path: "/serverPage",
    element: <ServerPage></ServerPage>,
  },
  {
    path: "/auth",
    element: <Authentication></Authentication>,
  },
  {
    path: "/agency",
    element: <Agency></Agency>,
  },
]);

export default function Nav() {
  return (
    <AliveScope>
      <RouterProvider router={router} />
    </AliveScope>
  );
}
