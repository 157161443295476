import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

const useAuthActions = () => {
  const userInfo = useSelector((state) => state.userInfo);
  // const navigate = useNavigate();

  const isLogin = !!userInfo?.id;
  const isVip = userInfo?.is_vip === 1;
  const isAuth = userInfo?.real_name && userInfo?.id_card;

  return { isLogin, isVip, isAuth };
};

export default useAuthActions;
