import request from "./request";

// const baseURL = "https://ha.zanbogroup.com";
const baseURL = "https://api.zeyuanhj.com";

// 图片上传
export const uploadUrl = `${baseURL}/file/upload?type=2`;

export const upload = (file, cancelToken) => {
  const data = new FormData();
  data.append("files", file);
  return request(uploadUrl, {
    method: "POST",
    data,
    cancelToken,
  });
};

// 文件预览
export const previewFile = (url) => baseURL + "/" + url;

// 登录
export const login = (data) => request.post(baseURL + "/login", data);
// 登录
export const getOpenID = (code) =>
  request.get(baseURL + `/get_proxy_id?code=${code}`);

// 注册
export const register = (data) => request.post(baseURL + "/register", data);

// 修改密码
export const rePwd = (data) =>
  request.post(baseURL + "/auth/my/update_pwd", data);

// 获取个人数据
export const getUserInfo = () => request.get(baseURL + "/auth/my/info");

// 注册
export const putUserInfo = (data) =>
  request.post(baseURL + "/auth/my/update", data);

// // 更新个人信息，头像和名称
// export const putUserInfo = (data) => request.patch("/api/my/update", data);

// 首页人员列表
export const getUserList = ({ page = 1, pageSize = 10, ...params }) =>
  request.get(baseURL + `/user/list/${page}/${pageSize}`, { params });

// 会员详情
export const getUserData = (id) => request.get(baseURL + `/auth/user/${id}`);

// 套餐列表
export const getCardList = () => request.get(baseURL + `/card/list`);

// 套餐详情
export const getCardInfo = (id) => request.get(baseURL + `/auth/card/${id}`);

// 我的合同列表
export const getMyContract = ({ page = 1, pageSize = 10 }) =>
  request.get(baseURL + `/api/active/list/${page}/${pageSize}`);

// 购买vip
export const payVip = (data) =>
  request.post(baseURL + "/auth/order/card_order", data);

// 收藏
export const putCollect = (id, type = "add") => {
  if (type === "delete") {
    return request.delete(baseURL + `/auth/collect/${id}`);
  }
  return request.post(baseURL + `/auth/collect/add_collect`, {
    target_id: id,
  });
};

// 查询红娘价格
export const getRedPrice = () => request.get(baseURL + `/auth/my/red_price`);

// 购买红娘服务
export const payReadOrder = (data) =>
  request.post(baseURL + "/auth/order/read_order", data);

// 收藏列表
export const getCollectList = ({ page = 1, pageSize = 15, ...params }) =>
  request.get(baseURL + `/auth/my/collect_list/${page}/${pageSize}`, {
    params,
  });

// 订单列表
export const getOrderList = ({ page = 1, pageSize = 10, ...params }) =>
  request.get(baseURL + `/auth/my/pay_detail/${page}/${pageSize}`, {
    params,
  });

// 实名认证
export const authUser = (data) =>
  request.post(baseURL + "/auth/user/real_name", data);
