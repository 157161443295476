import { useEffect, useState } from "react";
import {
  useNavigate,
  useParams,
  Navigate,
  useSearchParams,
  Link,
} from "react-router-dom";

import { Toast, Checkbox } from "react-vant";

import { getCardInfo, payVip } from "@/api";

import "./index.scss";
import fh_icon from "@/assets/images/icon/fh_icom@2x.png";
import vip_card from "@/assets/images/vip/vip_card@2x.png";
import logo from "@/assets/images/pay/logo@2x.png";
import wx_img from "@/assets/images/pay/wx@2x.png";
import td_img from "@/assets/images/pay/td@2x.png";
import zu_img from "@/assets/images/pay/zu@2x.png";

const Pay = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [data, setData] = useState({});
  const [checked, setChecked] = useState(true);
  const init = () => {
    fetchData();
  };

  const fetchData = async () => {
    if (!id) {
      navigate("/");
    }
    const toast = Toast.loading({
      message: "",
      duration: 0,
      forbidClick: false,
    });

    try {
      const res = await getCardInfo(id);

      setData(res);
      toast.clear();
    } catch (error) {
      const text = error?.msg || "网络异常，请稍后重试";
      // console.log("error", error);
      toast.clear();
      Toast.info(text);
    }
  };

  const renderList = () => {
    const { membership_benefits = "" } = data;
    const arr = membership_benefits.split("$$");
    return arr.splice(0, 3).map((item) => (
      <li className="Pay-vip-info-item" key={item}>
        {item}
      </li>
    ));
  };

  const handlePay = async () => {
    if (!checked) {
      Toast.info("未勾选协议");
      return;
    }
    const toast = Toast.loading({
      message: "",
      duration: 0,
      forbidClick: true,
    });
    let res = {};
    try {
      res = await payVip({ card_id: data.id });
      toast.clear();
      // console.log(res);
    } catch (error) {
      const text = error?.msg || "网络异常，请稍后重试";
      // console.log("error", error);
      toast.clear();
      Toast.info(text);
    }

    // eslint-disable-next-line
    WeixinJSBridge.invoke("getBrandWCPayRequest", res, function (res) {
      if (res.err_msg == "get_brand_wcpay_request:ok") {
        // 使用以上方式判断前端返回,微信团队郑重提示：
        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
        navigate("/mine");
      }
    });
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="Pay">
      <img
        src={fh_icon}
        alt=""
        className="Pay-back"
        onClick={() => navigate(-1)}
      />

      <div className="Pay-body">
        {data.id ? (
          <div className="Pay-card">
            <img src={vip_card} alt="" />
            <div className="Pay-card-name">{data.card_name}</div>
            <div className="Pay-card-price">
              <span className="Pay-card-price-cur">
                ¥{data.sale_price / 100}
              </span>
              {data.source_price ? (
                <span className="Pay-card-price-og">
                  ¥{data.source_price / 100}
                </span>
              ) : null}
            </div>
            <ul className="Pay-vip-info">{renderList()}</ul>
          </div>
        ) : null}

        <div className="Pay-content">
          <section className="Pay-section">
            <div className="Pay-section-head">
              <img src={logo} alt="" className="Pay-section-logo" />
              <div className="Pay-section-title">相约泽缘</div>
            </div>
            <div className="Pay-section-content">
              <p className="Pay-section-content-desc">
                泽缘婚介秉承“亲民服务，精准配对，诚信正规”的宗旨，
              </p>
              <p>斥巨资打造本地化相亲平台。</p>
              <p>一对一管家式服务，成就幸福婚姻！</p>
              <img src={wx_img} alt="" className="Pay-section-wx" />
            </div>
          </section>
          <section className="Pay-section">
            <div className="Pay-section-head">
              <img src={logo} alt="" className="Pay-section-logo" />
              <div className="Pay-section-title">加入会员</div>
            </div>
            <div className="Pay-section-content">
              <p className="Pay-section-content-desc">我希望在泽缘会员里</p>
              <p>有个如你一般的人 如春日清爽的风 如清晨温暖的光</p>
              <p>温暖而不炙热 覆盖所有</p>
              <p>当你进入泽缘</p>
              <p>我希望有个如你一般的人贯彻未来</p>
              <p>“陪你走遍全世界“</p>
              <img src={td_img} alt="" className="Pay-section-td" />
            </div>
          </section>
          <section className="Pay-section">
            <div className="Pay-section-head">
              <img src={logo} alt="" className="Pay-section-logo" />
              <div className="Pay-section-title">加入会员</div>
            </div>
            <div className="Pay-section-content">
              <img src={zu_img} alt="" className="Pay-section-zu" />
            </div>
          </section>
        </div>
        <div className="Pay-slot"></div>
      </div>

      {data.id ? (
        <div className="Pay-bottom">
          <div className="Pay-bottom-left">
            <div className="Pay-price">
              <span className="Pay-price-label">{data.card_name}</span>¥
              {data.sale_price / 100}
            </div>
            <div className="Pay-am">
              <Checkbox
                checked={checked}
                onChange={setChecked}
                checkedColor="#FF55AB"
                iconSize="14px"
              >
                <span className="Pay-am-text">我已阅读并同意</span>
                <Link to={"/vipPage"}>《会员VIP协议》</Link>
              </Checkbox>
            </div>
          </div>
          <button className="Pay-bottom-btn" onClick={handlePay}>
            立即开通
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default Pay;
