import { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import home from "@/assets/images/tab/home@2x.png";
import home_selected from "@/assets/images/tab/home_selected@2x.png";
import vip from "@/assets/images/tab/vip@2x.png";
import vip_selected from "@/assets/images/tab/vip_selected@2x.png";
import mine from "@/assets/images/tab/home@2x.png";
import mine_selected from "@/assets/images/tab/mine_selected@2x.png";

import "./index.scss";

export default function TabBar() {
  const data = [
    {
      path: "/",
      img: home,
      active: home_selected,
      ref: useRef(),
      text: "首页",
    },
    {
      path: "/vip",
      img: vip,
      active: vip_selected,
      ref: useRef(),
      text: "会员",
    },

    {
      path: "/mine",
      img: mine,
      active: mine_selected,
      ref: useRef(),
      text: "我的",
    },
  ];

  const location = useLocation();

  const [active, setActive] = useState(location?.pathname || "/");

  useEffect(() => {
    if (location?.pathname) {
      setActive(location?.pathname);
    }
  }, [location]);

  return (
    <div className="TabBar">
      {data.map((item) => {
        return (
          <Link
            className={`TabBar-item ${
              active === item.path ? "TabBar-item-active" : ""
            }`}
            to={item.path}
            key={item.path}
            ref={item.ref}
          >
            <img
              src={active === item.path ? item.active : item.img}
              alt=""
              className="TabBar-item-img"
            />
            <span className="TabBar-item-text">{item.text}</span>
          </Link>
        );
      })}
    </div>
  );
}
