// import { Toast } from "antd-mobile";
import { Toast } from "react-vant";
export const copy = (text) => {
  // text是复制文本
  // 创建input元素
  const el = document.createElement("input");
  // 给input元素赋值需要复制的文本
  el.setAttribute("value", text);
  // 将input元素插入页面
  document.body.appendChild(el);
  // 选中input元素的文本
  el.select();
  // 复制内容到剪贴板
  document.execCommand("copy");
  // 删除input元素
  document.body.removeChild(el);
  // Toast.show({
  //   content: "已复制到剪贴板！",
  // });
  Toast.success("已复制到剪贴板！");
};

export const formatCount = (count, fixed = 0, more = "") => {
  if (!count) {
    return "";
  } else if (count >= 10000) {
    return (count / 10000).toFixed(fixed) + "w" + more;
  } else if (count >= 1000) {
    return (count / 1000).toFixed(fixed) + "k" + more;
  } else {
    return count;
  }
};

export function amend(num1, num2, symbol) {
  let str1 = num1.toString(),
    str2 = num2.toString(),
    str1Length,
    str2Length;

  try {
    str1Length = str1.split(".")[1].length;
  } catch (error) {
    str1Length = 0;
  }
  try {
    str2Length = str2.split(".")[1].length;
  } catch (error) {
    str2Length = 0;
  }

  const step = Math.pow(10, Math.max(str1Length, str2Length));
  switch (symbol) {
    case "+":
      return (num1 * step + num2 * step) / step;
    case "-":
      return (num1 * step - num2 * step) / step;
    case "*":
      return (num1 * step * (num2 * step)) / step / step;
    case "/":
      return (num1 * step) / (num2 * step);

    default:
      return null;
  }
}

export const formatMoney = (money) => {
  if (!money) {
    return 0;
  }
  return amend(money, 100, "/");
};

export const validatePhone = (phone) => {
  // const reg =
  //   /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;
  const reg = /^1[3456789]\d{9}$/;
  return reg.test(phone);
};

export const secretPhone = (phone) => {
  if (!phone) {
    return "";
  }

  return phone.substring(0, 3) + "****" + phone.substring(7);
};

export const validateIdCard = (idCard) => {
  const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  return reg.test(idCard);
};

export const secretIdCard = (idCard) => {
  if (!idCard) {
    return "";
  }
  const temp = idCard.substring(4, 15);

  return idCard.replace(temp, "************");
};

export const secretName = (name) => {
  if (!name) {
    return "";
  }

  if (name.length < 3) {
    return name[0] + "*";
  }

  const arr = new Array(name.length - 2).fill("*");

  return name[0] + arr.join("") + name[name.length - 1];
};

export const validateBank = (bank) => {
  if (!bank) {
    return false;
  }

  var sumOdd = 0;
  var sumEven = 0;
  var wei = bank.split("").reverse();
  var length = wei.length;
  var i;

  // 转换为数值
  for (i = 0; i < length; i++) {
    wei[i] = Number(wei[i]);
  }

  for (i = 0; 2 * i + 1 < length; i++) {
    sumOdd += wei[2 * i];
  }

  for (i = 0; 2 * i + 1 < length; i++) {
    if (wei[2 * i + 1] * 2 > 9) {
      wei[2 * i + 1] = wei[2 * i + 1] * 2 - 9;
    } else {
      wei[2 * i + 1] *= 2;
    }
    sumEven += wei[2 * i + 1];
  }

  if ((sumOdd + sumEven) % 10 === 0) {
    return true;
  } else {
    return false;
  }
};

export const secretBank = (bank) => {
  if (!bank) {
    return "";
  }

  let reg = /^(.{4})(?:\d+)(.{4})$/;

  return bank.replace(reg, "$1 **** **** $2");
};

export function formatRichText(html = "") {
  if (!html) {
    return "";
  }

  let newContent = html.replace(/<img[^>]*>/gi, function (match, capture) {
    match = match.replace(/style="[^"]+"/gi, "").replace(/style='[^']+'/gi, "");
    match = match.replace(/width="[^"]+"/gi, "").replace(/width='[^']+'/gi, "");
    match = match
      .replace(/height="[^"]+"/gi, "")
      .replace(/height='[^']+'/gi, "");
    return match;
  });
  newContent = newContent.replace(/style="[^"]+"/gi, function (match, capture) {
    match = match
      .replace(/width:[^;]+;/gi, "max-width:100%;")
      .replace(/width:[^;]+;/gi, "max-width:100%;");
    return match;
  });
  // newContent = newContent.replace(/<br[^>]*\/>/gi, '');
  newContent = newContent.replace(/<p><\/p>/gi, "\n");
  newContent = newContent.replace(
    /\<img/gi,
    '<img style="max-width:100%;height:auto;display:block;margin:0 auto;"'
  );
  return newContent;
}

export const downloadContract = ({ url, fileName }) => {
  return new Promise(async (resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    // let token = localStorage.getItem("token") || "";
    // xhr.setRequestHeader("token", token);

    xhr.responseType = "blob";

    xhr.onload = function (e) {
      //如果请求执⾏成功
      if (this.status == 200) {
        // console.log(this.response.size);
        var blob = this.response;

        var a = document.createElement("a");

        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        //释放之前创建的URL对象
        window.URL.revokeObjectURL(url);
        resolve("");
      } else {
        reject("网络繁忙，请重试");
      }
    };

    xhr.onerror = function (e) {
      reject("网络繁忙，请重试");
    };
    //发送请求
    xhr.send();
  });
};

export const kfLink = "https://work.weixin.qq.com/kfid/kfc667cce61a19938f7";
