import { memo } from "react";

// import { PullToRefresh, InfiniteScroll } from "antd-mobile";

import { List, PullRefresh } from "react-vant";

// import "./index.scss";

const ScrollList = ({
  onRefresher = () => {},
  onScrollToLower = () => {},
  done = false,
  refresherEnabled = false,
  finishedText = "已全部加载",
  ...props
}) => {
  return (
    <PullRefresh onRefresh={onRefresher} disabled={refresherEnabled}>
      {/* List 组件可以与 PullRefresh 组件结合使用，实现下拉刷新的效果 */}
      <List
        finished={done}
        onLoad={onScrollToLower}
        finishedText={finishedText}
        offset={100}
        errorText="网络异常"
      >
        {props.children}
      </List>
      {/* {props.children}
      <InfiniteScroll
        loadMore={onScrollToLower}
        hasMore={!done}
      ></InfiniteScroll> */}
    </PullRefresh>
  );
};
export default memo(ScrollList);
