import { useState } from "react";
import { Radio, Slider } from "react-vant";
import "./index.scss";
const SearchFilter = ({
  onReset = () => {},
  onSubmit = () => {},
  show = false,
}) => {
  const [form, setForm] = useState({
    gender: "",
    age: [18, 60],
  });

  const handleReset = () => {
    onReset();
    setForm({ sex: "", age: [18, 60] });
  };

  const handleSubmit = () => {
    onSubmit({
      gender: form.gender,
      start_age: form.age[0],
      end_age: form.age[1],
    });
  };

  if (!show) {
    return null;
  }

  return (
    <div className="SearchFilter">
      <div className="SearchFilter-body">
        <div className="SearchFilter-body-row">
          <span className="SearchFilter-body-row-label">性别</span>
          <div className="SearchFilter-body-row-content">
            <Radio.Group
              direction="horizontal"
              value={form.gender}
              checkedColor="#FF55AB"
              onChange={(e) => setForm({ ...form, gender: e })}
            >
              <Radio name={1}>男</Radio>
              <Radio name={2}>女</Radio>
            </Radio.Group>
          </div>
        </div>
        <div className="SearchFilter-body-row">
          <span className="SearchFilter-body-row-label">年龄</span>
          <div className="SearchFilter-body-row-content">
            <div className="SearchFilter-slider">
              <span className="SearchFilter-slider-value">{form.age[0]}</span>
              <div className="SearchFilter-slider-body">
                <Slider
                  range
                  value={form.age}
                  onChange={(e) => setForm({ ...form, age: e })}
                  activeColor="#FF55AB"
                  min={18}
                  max={60}
                />
              </div>

              <span className="SearchFilter-slider-value">{form.age[1]}</span>
            </div>
          </div>
        </div>

        <div className="SearchFilter-btns">
          <div className="SearchFilter-reset" onClick={handleReset}>
            重置
          </div>
          <div className="SearchFilter-submit" onClick={handleSubmit}>
            确认
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchFilter;
