import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useActivate, useAliveController } from "react-activation";

import { useSelector, useDispatch } from "react-redux";

import { setUserInfo, clearUser } from "@/store/reducer";

import { getUserInfo, previewFile } from "@/api";

import { secretPhone, kfLink } from "@/utils";
import dayjs from "dayjs";

import logo from "@/assets/images/logo.jpg";
import nv_icon from "@/assets/images/icon/nu@2x.png";
import nan_icon from "@/assets/images/icon/nan@2x.png";
import dzb from "@/assets/images/mine/dzb@2x.png";
import zfzd_icom from "@/assets/images/mine/zfzd_icom@2x.png";
import midl_iocm from "@/assets/images/mine/midl_iocm@2x.png";
import kf_icom from "@/assets/images/mine/kf_icom@2x.png";
import wxkf from "@/assets/images/mine/wxkf@2x.png";
import more_icom from "@/assets/images/icon/more_icom_24@2x.png";
import order_icon from "@/assets/images/mine/order_icon@2x.png";
import dl_icon from "@/assets/images/mine/dl@2x.png";
import "./index.scss";

export default function Mine() {
  const { clear } = useAliveController();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => ({
    userInfo: state.userInfo,
  }));

  const isLogin = !!userInfo?.id;
  const isVip = userInfo?.is_vip === 1;

  const navList = [
    {
      img: dzb,
      label: "我的心动",
      path: "/heartbeat",
    },
    {
      img: zfzd_icom,
      label: "我的资料",
      path: "/profile",
    },
    {
      img: order_icon,
      label: "我的订单",
      path: "/order",
    },
    {
      img: midl_iocm,
      label: "修改密码",
      path: "/editPassword",
    },
    {
      img: wxkf,
      label: "联系客服",
      path: "kf",
    },
    {
      img: dl_icon,
      label: "成为代理",
      path: "/agency",
    },
    {
      img: kf_icom,
      label: "关于我们",
      path: "/about",
    },
  ];

  const toPage = (url) => {
    if (url === "/about" || url === "/agency") {
      navigate(url);
      return;
    }
    if (!isLogin) {
      navigate("/login");
      return;
    }
    if (url === "kf") {
      window.open(kfLink);
      return;
    }
    navigate(url);
  };

  const logout = () => {
    dispatch(clearUser());
    clear();
    navigate("/login");
  };

  const renderExp = () => {
    if (!userInfo.card_exp) {
      return "";
    }
    return dayjs(userInfo.card_exp).format("YYYY-MM-DD");
  };

  const renderList = () => {
    const { membership_benefits = "" } = userInfo;
    if (!membership_benefits) {
      return null;
    }
    const arr = membership_benefits.split("$$");
    return arr.splice(0, 3).map((item) => (
      <li className="Mine-vip-info-item" key={item}>
        {item}
      </li>
    ));
  };

  useActivate(() => {
    if (isLogin) {
      getUserInfo().then((res) => {
        dispatch(setUserInfo(res || {}));
      });
    }
  });
  // console.log(userInfo);
  const { phone, head_url, user_name, gender } = userInfo;

  return (
    <div className="Mine">
      <div className="Mine-head">
        <div className="Mine-head-wlogo"></div>
        {!isVip ? (
          <div className="Mine-head-bg">
            <div className="Mine-tip">
              <p className="Mine-tip-title">未开通会员</p>
              <p className="Mine-tip-text">开通VIP专属服务，快速脱单</p>
            </div>
          </div>
        ) : null}

        <div className="Mine-info">
          <div className="Mine-info-cover">
            <img
              src={head_url ? previewFile(head_url) : logo}
              alt=""
              onClick={() => {
                if (!isLogin) {
                  navigate("/login");
                  return;
                }
              }}
            />
          </div>
          <div className="Mine-info-right">
            <div
              className="Mine-info-name"
              onClick={() => {
                if (!isLogin) {
                  navigate("/login");
                  return;
                }
              }}
            >
              {isLogin ? user_name : "请先登录"}{" "}
              {!!gender && (
                <img
                  className="Mine-sex"
                  src={gender == 1 ? nan_icon : nv_icon}
                ></img>
              )}
            </div>
            {phone && (
              <div className="Mine-info-phone">
                手机号：{secretPhone(phone)}
              </div>
            )}
          </div>
        </div>
      </div>
      {isVip ? (
        <div className="Mine-vip">
          <div className="Mine-vip-name">{userInfo.card_name}</div>
          <div className="Mine-vip-date">到期时间：{renderExp()}</div>
          <ul className="Mine-vip-info">{renderList()}</ul>
        </div>
      ) : null}

      <div className="Mine-body">
        <ul className="Mine-nav">
          {navList.map((item) => (
            <li
              className="Mine-nav-item"
              key={item.label}
              onClick={() => {
                toPage(item.path);
              }}
            >
              <img
                src={item.img}
                className="Mine-nav-item-img"
                alt={item.label}
              />
              <div className="Mine-nav-item-content">
                <span>{item.label}</span>
                <img
                  src={more_icom}
                  className="Mine-nav-item-more"
                  alt={"more"}
                />
              </div>
            </li>
          ))}
        </ul>
        {isLogin && (
          <button className="Mine-logout" onClick={logout}>
            退出
          </button>
        )}
        {isLogin && <div className="logout-slot"></div>}
      </div>
    </div>
  );
}
