import { useState, useEffect } from "react";

import ScrollList from "@/components/ScrollList";
import NavBar from "@/components/NavBar";

import UserItem from "@/components/UserItem";

import { getCollectList } from "@/api";

import "./index.scss";

const Heartbeat = () => {
  const [listData, setListData] = useState({
    data: [],
    isLoading: false,
    done: false,
    page: 1,
    triggered: false,
  });

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    fetchData(1);
  };

  const fetchData = async (page, pageSize = 16) => {
    return new Promise(async (resolve) => {
      const { data } = listData;
      try {
        // const fetchWay = params.type === 2 ? getBoxList : getMyContractList;
        const res = await getCollectList({
          page,
        });

        const list = res?.list || [];
        const dataTotal = res?.count || 0;
        let arr = [];
        if (page === 1) {
          arr = [];
        } else {
          arr = data;
        }
        let done = false;
        arr = arr.concat(list);
        if (arr.length === dataTotal) {
          done = true;
        }
        page++;

        setListData({
          page,
          done,
          data: arr,
        });
      } catch (error) {
        setListData({ ...listData, done: true });
      } finally {
        resolve();
      }
    });
  };

  const handleRefresher = async () => {
    init();
  };

  const handleScrollToLower = async () => {
    if (listData.page === 1) {
      return;
    }
    await fetchData(listData.page);
  };

  const { done, data } = listData;
  return (
    <div className="Heartbeat">
      <div className="Heartbeat-bar">
        <NavBar title="我的心动"></NavBar>
      </div>
      <ScrollList
        onRefresher={init}
        onScrollToLower={handleScrollToLower}
        done={done}
        finishedText={data.length === 0 ? "" : "已全部加载"}
      >
        <div className="Heartbeat-list">
          {data.map((item) => (
            <UserItem key={item.id} data={item}></UserItem>
          ))}
        </div>
      </ScrollList>
    </div>
  );
};

export default Heartbeat;
