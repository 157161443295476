import { useState, useEffect } from "react";
import { Provider } from "react-redux";

// import dayjs from "dayjs";

import store from "./store";

import Nav from "./navigator";

import { setUserInfo, setToken, setCode } from "@/store/reducer";

import { getUserInfo, getOpenID } from "@/api";

import "./flexible";
// import "lib-flexible";
// import "./App.scss";
// import "./rembase";

function App() {
  const [initDone, setInitDone] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const params = new URLSearchParams(window.location.search);
    const paramsObj = Object.fromEntries(params.entries());
    // console.log("paramsObj", paramsObj);
    if (!paramsObj?.code) {
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx39354d09961907c4&redirect_uri=${encodeURI(
        window.location.origin + window.location.pathname
      )}&response_type=code&scope=snsapi_base&state=${encodeURIComponent(
        window.location.search
      )}#wechat_redirect`;

      // console.log(url);
      // window.location.href = url;

      window.location.replace(url);
      return;
    } else {
      const { code, state } = paramsObj;

      if (!state) {
        try {
          const res = await getOpenID(code);
          if (res?.open_id) {
            store.dispatch(setCode(res || {}));
          }
        } catch (error) {}

        const token = localStorage.getItem("token") || "";
        if (token) {
          store.dispatch(setToken(token));

          try {
            const user = await getUserInfo();
            store.dispatch(setUserInfo(user || {}));
          } catch (error) {}
        }
      } else {
        const u = `${window.location.origin}${
          window.location.pathname
        }${decodeURIComponent(state)}&code=${code}`;
        window.location.replace(u);
        return;
      }
    }

    // const token = localStorage.getItem("token") || "";
    // if (token) {
    //   store.dispatch(setToken(token));

    //   try {
    //     const user = await getUserInfo();
    //     store.dispatch(setUserInfo(user || {}));
    //   } catch (error) {}
    // }

    setInitDone(true);
  };

  return <Provider store={store}>{initDone && <Nav></Nav>}</Provider>;
}

export default App;
