import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";

import { setHomeActive } from "@/store/reducer";
// import Tab from "@/components/Tab";
import TabBar from "@/components/TabBar";
export default function Home() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setHomeActive(true));
  }, []);
  return (
    <div>
      <Outlet></Outlet>
      <TabBar></TabBar>
    </div>
  );
}
