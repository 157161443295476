import "./index.scss";

import NavBar from "@/components/NavBar";

const About = () => {
  return (
    <div className="About">
      <div className="About-head">
        <NavBar title="关于我们"></NavBar>
      </div>
      <div className="About-body">
        <h2>泽缘婚介，为天下有情人牵一条红线</h2>
        <p>
          泽缘婚介，这个名字背后蕴藏着深厚的情感与责任。作为广东本土的一颗璀璨明珠，它不仅仅是一个婚介品牌，更是无数有情人心中那份对美好爱情的期盼与寄托。
        </p>
        <p>
          追溯泽缘婚介的历史，其前身是一家颇具口碑的英姐媒人店。在那个信息相对闭塞的年代，英姐以其敏锐的洞察力和丰富的经验，为众多单身男女牵线搭桥，成就了一段段佳话。然而，随着时代的变迁和社会的进步，人们对于婚介服务的需求也在不断变化。为了更好地满足广大单身男女的需求，英姐媒人店在2024年5月8日迎来了华丽的转身，正式升级为“广州泽缘生活服务有限公司”，并开通了微信服务号，开启了全新的服务模式。
        </p>
        <p>
          在多年的红娘经验中，泽缘婚介始终坚持诚信经营的原则，以真诚的态度为每一位客户提供优质的服务。我们深知，婚姻是人生中最重要的选择之一，因此我们始终秉持着对客户负责、对爱情尊重的态度，为每一位单身男女精心挑选合适的伴侣。正是凭借着这种真诚和负责的态度，泽缘婚介赢得了广大客户的信赖和好评，也积累了成千上万的优质资源。
        </p>
        <p>
          在精准匹配方面，泽缘婚介更是下足了功夫。我们深入了解每一位客户的需求和期望，通过科学的方法和丰富的经验，为客户推荐最合适的伴侣。这种精准匹配的方式不仅提高了成功率，也让更多的新人步入了婚姻的殿堂。
        </p>
        <p>
          如今，泽缘婚介已经成为广东地区乃至全国知名的婚介品牌。我们始终坚信，爱情是美好的，婚姻是神圣的。因此，我们将继续秉持着诚信、真诚、负责的态度，为更多的有情人牵线搭桥。
        </p>
        <div className="About-bottom">祝愿天下有情人终成眷属。</div>
      </div>
    </div>
  );
};

export default About;
