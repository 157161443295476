import "./index.scss";

import NavBar from "@/components/NavBar";

const VipPage = () => {
  const list = [
    {
      title: "一、协议概述",
      desc: "本协议为泽缘婚介VIP会员购买协议，旨在明确泽缘婚介（以下简称“本公司”）与购买VIP会员服务的用户（以下简称“会员”）之间的权利与义务。通过购买VIP会员服务，会员将享受本公司提供的一系列专属服务。",
    },
    {
      title: "二、服务内容",
      list: [
        {
          label: "精准匹配",
          text: "本公司将根据会员的个人信息和需求，为其筛选并推荐合适的相亲对象。",
        },
        {
          label: "优先推荐",
          text: "VIP会员将享有更多的相亲对象推荐机会，提高匹配成功率。",
        },
        {
          label: "个性化咨询",
          text: "本公司将为VIP会员提供个性化的情感咨询和婚恋建议，帮助会员更好地处理情感问题。",
        },
        {
          label: "活动参与",
          text: "VIP会员将享有优先参与本公司举办的各类线上线下活动的权利，如相亲活动、情感讲座等。",
        },
      ],
    },
    {
      title: "三、购买与支付",
      list: [
        {
          text: "会员需按照本公司公布的价格购买VIP会员服务，并支付相应费用。",
        },
        {
          text: "支付成功后，会员将自动成为VIP会员，并享受相应的会员服务。",
        },
      ],
    },
    {
      title: "四、会员权益",
      list: [
        {
          text: "会员将享受本公司提供的上述VIP会员服务。",
        },
        {
          text: "会员在享受服务过程中，如有任何疑问或建议，可随时与本公司联系，本公司将竭诚为您解答和提供帮助。",
        },
      ],
    },
    {
      title: "五、服务期限",
      list: [
        {
          text: "VIP会员服务的期限自会员支付成功之日起计算，具体期限以本公司公布的信息为准。",
        },
        {
          text: "服务期限届满后，如会员需继续享受VIP会员服务，需重新购买并支付相应费用。",
        },
      ],
    },
    {
      title: "六、违约责任",
      list: [
        {
          text: "如会员在购买或使用过程中违反本协议规定，本公司有权立即终止其VIP会员服务，并不予退还已支付的费用。",
        },
      ],
    },
    {
      title: "六、免责条款",
      list: [
        {
          text: "本公司提供的服务仅供参考，会员在使用服务过程中应自行判断信息的真实性和可靠性，并承担由此产生的风险。",
        },
        {
          text: "本公司不承担因会员个人原因导致的任何损失或责任，包括但不限于会员与相亲对象之间的纠纷、会员在参加活动时发生的意外等。",
        },
      ],
    },
    {
      title: "七、联系方式",
      list: [
        {
          text: "如会员有任何疑问或需要帮助，请随时联系本公司客服，我们将竭诚为您服务。",
        },
      ],
    },
  ];
  return (
    <div className="VipPage">
      <div className="VipPage-head">
        <NavBar title="会员VIP购买协议"></NavBar>
      </div>
      <div className="VipPage-body">
        <ul className="VipPage-list">
          {list.map((item) => (
            <li key={item.title} className="VipPage-list-item">
              <div className="VipPage-list-title">{item.title}</div>
              {item.desc && <p className="VipPage-list-desc">{item.desc}</p>}
              {item.list?.map((sitem, index) => (
                <li className="VipPage-list-content">
                  <span>{index + 1}.</span>
                  {sitem.label && (
                    <span className="VipPage-list-label">{sitem.label}：</span>
                  )}
                  {sitem.text}
                </li>
              ))}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default VipPage;
