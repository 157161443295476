import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Navigate, useSearchParams } from "react-router-dom";

import { Link } from "react-router-dom";

import md5 from "js-md5";

import NavBar from "@/components/NavBar";
import { Toast } from "react-vant";

import { setUserInfo, setToken } from "@/store/reducer";

import { register, getUserInfo } from "@/api";

import { validatePhone } from "@/utils/index";

import logo from "@/assets/images/login/logo@2x.png";
import slogan from "@/assets/images/login/slogan@2x.png";
import phone_icon from "@/assets/images/login/phone@2x.png";
import lock_icon from "@/assets/images/login/lock@2x.png";
import eye_open from "@/assets/images/login/eye_open@2x.png";
import eye_close from "@/assets/images/login/eye_close@2x.png";
import "./index.scss";

export default function Registered() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const proxy_id = searchParams.get("proxy_id");
  const isRegistered = useSelector((state) => {
    return !!state?.userInfo?.user_name;
  });
  const code = useSelector((state) => {
    return state?.code;
  });
  // const [type, setType] = useState("password");

  const [checked, setChecked] = useState(true);

  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  const [form, setForm] = useState({
    phone: "",
    password: "",
    _password: "",
  });

  const handleRegistered = async () => {
    const { phone, password, _password } = form;
    if (!phone) {
      Toast.info("请输入账号");

      return;
    }
    if (!validatePhone(phone)) {
      Toast.info("请输入正确的手机号");
      return;
    }

    if (!password) {
      Toast.info("请输入密码");

      return;
    }

    if (password.length < 6) {
      Toast.info("密码不能少于6位");

      return;
    }
    if (!_password) {
      Toast.info("请再次输入密码");

      return;
    }
    if (_password !== password) {
      Toast.info("两次输入密码不一致");

      return;
    }

    const toast = Toast.loading({
      message: "正在注册…",
      duration: 0,
      forbidClick: true,
    });

    try {
      const sendObj = {
        phone,
        password: md5(password),
        ...code,
      };
      if (proxy_id) {
        sendObj.proxy_id = parseInt(proxy_id);
      }

      const data = await register(sendObj);

      dispatch(setToken(data.token));
      const user = await getUserInfo();
      toast.clear();
      dispatch(setUserInfo(user || {}));
      Toast.success({
        message: "注册成功",
        duration: 1000,
        onClose: () => {
          navigate("/profile?is_registered=1", { replace: true });
        },
      });

      // navigate("/", { replace: true });
    } catch (error) {
      const text = error?.msg || "注册失败，请稍后重试";
      // console.log("error", error);
      toast.clear();
      Toast.info(text);
    }
  };

  return isRegistered ? (
    <Navigate to="/" replace={true}></Navigate>
  ) : (
    <div className="Registered">
      <NavBar title="注册" black></NavBar>
      <img src={logo} alt="logo" className="Registered-logo" />
      <img src={slogan} alt="slogan" className="Registered-slogan" />
      <div className="Registered-form">
        <div className="Registered-form-row">
          <img src={phone_icon} alt="" className="Registered-form-img" />
          <input
            type="text"
            value={form.phone}
            onInput={(e) => {
              setForm({ ...form, phone: e.target.value });
            }}
            placeholder="请输入手机号"
          />
        </div>
        <div className="Registered-form-row">
          <img src={lock_icon} alt="" className="Registered-form-img" />
          <input
            type={passwordType}
            value={form.password}
            onInput={(e) => {
              setForm({ ...form, password: e.target.value });
            }}
            placeholder="请输入密码"
          />
          <img
            src={passwordType === "password" ? eye_close : eye_open}
            alt=""
            className="Registered-eye-icon"
            onClick={() => {
              setPasswordType(
                passwordType === "password" ? "text" : "password"
              );
            }}
          />
        </div>
        <div className="Registered-form-row">
          <img src={lock_icon} alt="" className="Registered-form-img" />
          <input
            type={passwordType1}
            value={form._password}
            onInput={(e) => {
              setForm({ ...form, _password: e.target.value });
            }}
            placeholder="请再次输入密码"
          />
          <img
            src={passwordType1 === "password" ? eye_close : eye_open}
            alt=""
            className="Registered-eye-icon"
            onClick={() => {
              setPasswordType1(
                passwordType1 === "password" ? "text" : "password"
              );
            }}
          />
        </div>

        <button className="Registered-form-btn" onClick={handleRegistered}>
          注册
        </button>
        <div
          className="Registered-form-agreement"
          onClick={() => {
            setChecked(!checked);
          }}
        >
          <div
            className={`Registered-check ${
              checked ? "Registered-check-active" : ""
            }`}
          ></div>
          已阅读并同意<Link to={"/privacy"}>《隐私声明》</Link>
        </div>
      </div>
    </div>
  );
}
