import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useBack = () => {
  const homeActive = useSelector((state) => state.homeActive);
  const navigate = useNavigate();

  const back = () => {
    if (window.location.href.includes("/registered")) {
      navigate("/login", { replace: true });
    } else if (homeActive) {
      // console.log("homeActive", homeActive);
      navigate(-1);
    } else {
      navigate("/", { replace: true });
    }
  };

  return back;
};

export default useBack;
