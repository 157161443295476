import { memo, useState } from "react";
import { Image, Toast, Dialog } from "react-vant";
import { useNavigate } from "react-router-dom";
import nv_icon from "@/assets/images/icon/nu@2x.png";
import nan_icon from "@/assets/images/icon/nan@2x.png";
import heart from "@/assets/images/icon/heart@2x.png";
import heart_1 from "@/assets/images/icon/heart_1@2x.png";

import { previewFile, putCollect } from "@/api";

import useAuthActions from "@/hook/useAuthActions";

import "./index.scss";
import w_logo from "@/assets/images/w_logo@2x.png";

const UserItem = ({ data = {} }) => {
  const navigate = useNavigate();
  const { isLogin, isVip } = useAuthActions();
  const [isCollect, setIsCollect] = useState(data?.is_collect === 1);

  const renderLabel = () => {
    const arr = [];

    if (data.constellations) {
      arr.push(data.constellations);
    }

    if (data.age) {
      arr.push(data.age + "岁");
    }
    if (data.height) {
      arr.push(data.height + "CM");
    }

    if (data.weight) {
      arr.push(data.weight / 100 + "KG");
    }
    // if (data.marriage === "单身") {
    //   arr.push(data.marriage);
    // }
    if (
      data.current_place_of_residence_city &&
      !data.current_place_of_residence_city.startsWith("市") &&
      !data.current_place_of_residence_city.startsWith("省")
    ) {
      arr.push(data.current_place_of_residence_city);
    }
    if (data.occupation) {
      arr.push(data.occupation);
    }

    if (data.house_available == 1) {
      arr.push("有房");
    }
    if (data.blood_type) {
      arr.push(data.blood_type);
    }

    return arr.map((item) => (
      <span className="UserItem-label-item" key={item + data.user_name}>
        {item}
      </span>
    ));
  };

  const renderImgs = () => {
    const arr = data.life_photos.split(",");
    const slicedArr = arr.slice(0, 3);
    return slicedArr.map((item) => (
      <div className="UserItem-img-item" key={item}>
        <Image src={previewFile(item)} lazyload fit="cover" alt="" />
      </div>
    ));
  };

  const handleClick = () => {
    if (!isLogin) {
      navigate("/login");
    } else if (!isVip) {
      Dialog.confirm({
        title: "未开通会员",
        message: "开通会员后方可查看会员资料",
        onCancel: () => {},
        onConfirm: () => {
          navigate("/vip");
        },
        confirmButtonText: "前往开通",
        confirmButtonColor: "#FF55AB",
        cancelButtonText: "取消",
        // cancelButtonColor: "#999999",
      });
    } else {
      navigate("/user/" + data.id);
    }
  };

  const handleCollect = async () => {
    if (!isLogin) {
      navigate("/login");
      return;
    }

    try {
      await putCollect(data.id, isCollect ? "delete" : "add");
      setIsCollect(!isCollect);
      Toast.info({
        message: isCollect ? "已取消收藏" : "添加收藏成功",
        duration: 1000,
      });
    } catch (error) {
      const text = error?.msg || "网络异常，请稍后重试";
      // console.log("error", error);
      Toast.info(text);
    }
  };

  return (
    <div className="UserItem" onClick={handleClick}>
      <div className="UserItem-cover">
        <Image src={previewFile(data.head_url)} lazyload fit="cover" alt="" />
      </div>
      <div className="UserItem-info">
        <div className="UserItem-top">
          <div className="UserItem-name">
            {data.user_name}{" "}
            <img
              className="UserItem-sex"
              src={data.gender == 1 ? nan_icon : nv_icon}
            ></img>
          </div>
          <div
            className="UserItem-collect"
            onClick={(e) => {
              e.stopPropagation();
              handleCollect();
            }}
          >
            <img src={isCollect ? heart_1 : heart}></img>
          </div>
        </div>
        <div className="UserItem-labels">{renderLabel()}</div>
        <p className="UserItem-intro">{data.personal_signature}</p>
        <div className="UserItem-imgs">{renderImgs()}</div>
      </div>
      <img src={w_logo} alt="" className="UserItem-logo" />
    </div>
  );
};

export default memo(UserItem);
